import axios from 'axios';

const configureAxios = () => {
    const jwt = localStorage.getItem('jwt');

    if (jwt) {
        axios.defaults.headers.common.jwt = jwt;
    }

    axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
};

export default configureAxios;
