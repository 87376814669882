
export const generateLeadsCSV = (title, leads) => {
    const formattedLeads = [`Name, Email, Phone, Created At, Virtual Lead?, Notes`];
    leads.forEach((lead) => {
        formattedLeads.push(`${lead.name}, ${lead.email}, ${lead.phone}, ${lead.created_at}, ${lead.isVirtualLead ? "Yes": "No"}, ${lead.notes || ""}`);
    });
    let formattedLeadsString = formattedLeads.join("\n");
    formattedLeadsString = 'data:text/csv;charset=UTF-8,' + formattedLeadsString;

    const downloadLink = document.createElement('a');
    const event = new MouseEvent('click');
    downloadLink.download = title + "_leads.csv";
    downloadLink.href = formattedLeadsString;
    downloadLink.target = '_blank';
    downloadLink.dispatchEvent(event);
};
