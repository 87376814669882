import React, { useState, useEffect } from 'react';
import {useSpring, animated, config, useTransition} from 'react-spring';
import { Link } from 'react-router-dom';
import { FaCheck, FaPaperPlane, FaCopy, FaExclamation, FaFileDownload, FaEdit, FaTrashAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Modal from 'components/common/Modal/Modal.jsx';
import Dialog from 'components/common/Dialog/Dialog.jsx';
import HR from 'components/common/HR/HR.jsx';
import { generateLeadsCSV } from 'utils/generateCSV.js';
import LeadFollowUp from 'components/LeadFollowUp/LeadFollowUp.jsx';
import { searchLeads } from "utils/leads.js";
import 'components/HomeCard/HomeCard.scss';

const HomeCard = ({
    home,
    lead,
    showLeads,
    showLead,
    showOverview,
    homeID,
    leadID,
    updateLead,
    sendFollowUp,
    sendFollowUpAll,
    deleteHome,
    deleteLeadByHome
}) => {

    const [deleteHomeModalOpen, setDeleteHomeModalOpen] = useState(false);
    const [targetHomeId, setTargetHomeId] = useState(null);

    const [deleteLeadModalOpen, setDeleteLeadModalOpen] = useState(false);
    const [targetLeadId, setTargetLeadId] = useState(null);

    const [sendFollowUpAllModalOpen, setSendFollowUpAllModalOpen] = useState(false);

    const [showCopySuccess, setShowCopySucess] = useState(false);

    const [optionsDropdownOpen, setOptionsDropdownOpen] = useState(false);

    const copyVirtualOpenHouseLink = () => {
        const el = document.createElement('textarea');
        el.value = window.location.host + "/v/" + home.homeID;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        setShowCopySucess(true);
        setTimeout(() => {
            setShowCopySucess(false);
        }, 2000)
    };

    const dropdownTransitions = useSpring({
        to: {
            transform: optionsDropdownOpen ? 'translate(0%, 0%)' : 'translate(0%, 5%)',
            opacity: optionsDropdownOpen ? 1 : 0,
            display: optionsDropdownOpen ? "block" : "none"
        },
        config: config.default,
    });

    const copyTransitions = useTransition(showCopySuccess, null, {
        from: {
            position: 'absolute',
            transform: 'translate(0%, 5%)',
            opacity: 0,
        },
        enter: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        leave: {
            transform: 'translate(0%, 5%)',
            opacity: 0,
        },
        config: config.default,
    });

    return (
        <div className="HomeCard" key={home.homeID} id={home.homeID}>
            <Modal isOpen={deleteHomeModalOpen} handleClose={() => setDeleteHomeModalOpen(false)}>
                <Dialog
                    title="Are you sure?"
                    subtitle="By deleting a home you will also delete all leads associated with it."
                    buttonTitle="Delete"
                    onClick={() => {
                        deleteHome(targetHomeId);
                        setDeleteHomeModalOpen(false);
                    }}
                />
            </Modal>
            <Modal isOpen={deleteLeadModalOpen} handleClose={() => setDeleteLeadModalOpen(false)}>
                <Dialog
                    title="Are you sure?"
                    subtitle="You will not be able to recover this lead once it is deleted."
                    buttonTitle="Delete"
                    onClick={() => {
                        deleteLeadByHome(home.homeID, targetLeadId);
                        setDeleteLeadModalOpen(false);
                    }}
                />
            </Modal>
            <Modal isOpen={sendFollowUpAllModalOpen} handleClose={() => setSendFollowUpAllModalOpen(false)}>
                <Dialog
                    title="Are you sure?"
                    subtitle={(() => {
                        let unFollowedUpLeadsCount = home.leads.filter(lead => !lead.follow_up_sent).length
                        return unFollowedUpLeadsCount === 1 ?
                            "This will send a follow up message to the one lead you have not yet followed up with for this home."
                            :
                            `This will send a follow up message to each of the ${unFollowedUpLeadsCount} leads you have not yet follow up with for this home.`
                    })()}
                    buttonTitle="Send"
                    onClick={() => {
                        sendFollowUpAll(home.homeID);
                        setSendFollowUpAllModalOpen(false);
                    }}
                />
            </Modal>
            <div className="HomeCardTop">
                <div className="HomeCardTopLeft">
                    {home.image && (
                        <div style={{ backgroundImage: `url(${home.image})` }} className="HomeCardTopImage" />
                    )}
                    <div className="HomeCardContent">
                        <Link to={showLead ? `/app/leads?homeID=${home.homeID}` : "/app/homes"}><div className="HomeCardTitle">{ home.title }</div></Link>
                        <HR className="HomeCardHR" />
                        <div className="HomeCardSubtitle">{ showLead ? lead.name : home.address }</div>
                    </div>
                </div>
                <div className="HomeCardTopRight">
                    <div className="HomeCardTopRightChips">
                        { showLead ? (
                            !lead.follow_up_sent ?
                                <div className="HomeCardTopRightChip HomeCardTopRightChip--warn">Follow-Up Not Sent<FaExclamation className="HomeCardTopRightChip--icon" /></div>
                                :
                                <div className="HomeCardTopRightChip">Follow-Up Sent<FaCheck className="HomeCardTopRightChip--icon"/></div>
                            ) : (
                                home.leads.find(lead => !lead.follow_up_sent) ?
                                <div className="HomeCardTopRightChip HomeCardTopRightChip--warn">Follow-Ups Not Sent<FaExclamation className="HomeCardTopRightChip--icon" /></div>
                                :
                                <div className="HomeCardTopRightChip">All Leads Followed-Up<FaCheck className="HomeCardTopRightChip--icon"/></div>
                            )
                        }
                    </div>

                    <div className="HomeCardTopRightDropdown" onMouseLeave={() => setOptionsDropdownOpen(false)}>

                        <div className="HomeCardTopRightDropdownTitle" onMouseEnter={() => setOptionsDropdownOpen(true)} onClick={() => setOptionsDropdownOpen(!optionsDropdownOpen)} >Options</div>

                        <animated.div style={dropdownTransitions} className="HomeCardTopRightDropdownOptions" onMouseLeave={() => setOptionsDropdownOpen(false)}>
                            { !showLead && home.leads.filter(lead => !lead.follow_up_sent).length > 0 && <div className="HomeCardTopRightDropdownOption" onClick={() => setSendFollowUpAllModalOpen(true)}><FaPaperPlane className="HomeCardLeadDeleteButton"/> Send All Follow-Ups</div> }
                            <div className="HomeCardTopRightDropdownOption"
                                 onClick={() => generateLeadsCSV(home.title, home.leads)}>
                                <FaFileDownload className="HomeCardLeadDeleteButton" /> Export Leads
                            </div>
                            {showLead &&
                                <div className="HomeCardTopRightDropdownOption"
                                    onClick={() => {
                                        setTargetLeadId(lead._id);
                                        setDeleteLeadModalOpen(true);
                                    }}
                                >
                                    <FaTrashAlt className="HomeCardLeadDeleteButton" />  Delete Lead
                                </div>
                            }
                            <div className="HomeCardTopRightDropdownOption" onClick={() => {
                                setTargetHomeId(home.homeID);
                                setDeleteHomeModalOpen(true);
                            }}>
                                <FaTrashAlt className="HomeCardLeadDeleteButton" /> Delete House
                            </div>
                            <div className="HomeCardTopRightDropdownOption">
                                <Link to={`/app/homes/edit/${home.homeID}`}><FaEdit /> Edit</Link>
                            </div>
                        </animated.div>
                    </div>
                </div>
            </div>
            {
                showLeads ? (
                    home.leads.length > 0 ?
                        home.leads.map((lead, index) => (
                            <div className="HomeCardLeadCard HomeCardLeadCard--hoverable" key={lead.email + index}>
                                <Link to={`/app/homes/${home.homeID}/leads/${lead._id}`}>
                                    <div className="HomeCardLeadCardLeft">
                                        <div className="HomeCardLeadCardInfo--name HomeCardLeadCardInfo--nameBold">{lead.name}</div>
                                        <div className="HomeCardLeadCardInfo--email HomeCardLeadCardInfo--leadsList">{lead.email}   {lead.phone}</div>
                                        {/*<div className="LeadCardInfo--phone">{lead.phone}</div>*/}
                                    </div>
                                </Link>
                                <div className="HomeCardLeadCardRight" onClick={e => e.stopPropagation()}>
                                    <div className="HomeCardLeadButton">
                                        <LeadFollowUp followUpSent={lead.follow_up_sent}
                                                      sendFollowUp={() => sendFollowUp(lead._id, home.homeID)}/>
                                    </div>
                                    <FaTrashAlt className="HomeCardLeadDeleteButton HomeCardLeadDeleteButton--leadsList" onClick={() => {
                                        setTargetLeadId(lead._id);
                                        setDeleteLeadModalOpen(true);
                                    }} />
                                </div>
                            </div>
                        ))
                        : (
                            <div className="HomeCardNoLeadsMessage">
                                There are no leads collected yet for this listing.
                                To collect leads, start an Open House for this listing.
                            </div>
                        )
                ) : null
            }
            {
                showOverview ? (
                    <div className="HomeCardBottom">
                        <div className="HomeCardBottomLeft">
                            <Link className="HomeCardBottomLeftStart" to={`/app/dashboard/${home.homeID}/openhouse`}>Start Open House</Link>
                            <Link className="HomeCardBottomLeftLeads" to={`/app/leads?homeID=${home.homeID}`}>View Leads {home.leads.length > 0 && `(${home.leads.length})`}</Link>
                        </div>
                        <div className="HomeCardBottomRight">
                            <div style={{ opacity: 0 }}><FaCopy className="HomeCardBottomRightCopyIcon"/>Copy Virtual Link</div>
                            {copyTransitions.map(({ item, key, props }) => {
                                return !item ?
                                    <animated.div className="HomeCardBottomRightLink" style={props} key={key} onClick={copyVirtualOpenHouseLink}><FaCopy className="HomeCardBottomRightCopyIcon"/>Copy Virtual Link</animated.div>
                                :
                                    <animated.div className="HomeCardBottomRightLink" style={props} key={key}>Link Copied!</animated.div>
                            })}
                        </div>
                    </div>
                ) : null
            }
            {
                showLead ? (
                    <div className="HomeCardBottom HomeCardBottom--column">
                        <div className="HomeCardLeadCard">
                            <div className="HomeCardLeadCardLeft">
                                <div className="HomeCardLeadCardInfo--name">Name: {lead.name}</div>
                                <div className="HomeCardLeadCardInfo--email">Email: {lead.email}</div>
                                <div className="HomeCardLeadCardInfo--phone">Phone: {lead.phone}</div>
                                <div className="HomeCardLeadCardInfo--phone">Date: {lead.created_at ? `${new Date(lead.created_at).toLocaleDateString()} ${new Date(lead.created_at).toLocaleTimeString()}` : null}</div>
                                <div className="HomeCardLeadCardInfo--phone">Virtual Lead: {lead.isVirtualLead ? "Yes": "No"}</div>
                            </div>
                        </div>
                        {
                            !lead.follow_up_sent && (
                                <div className="HomeCardLeadCardBottom">
                                    <div className="HomeCardLeadButton HomeCardLeadButton--below">
                                        <LeadFollowUp followUpSent={lead.follow_up_sent}
                                                      sendFollowUp={() => sendFollowUp(lead._id, home.homeID)}/>
                                    </div>
                                </div>
                            )
                        }
                        <textarea
                            className="HomeCardLeadCardInfo--notes"
                            type="text"
                            placeholder="Add notes for this lead here"
                            rows={10}
                            onBlur={(e) => updateLead({
                                email: lead.email,
                                name: lead.name,
                                phone: lead.phone,
                                notes: e.target.value
                            })}
                        >
                            {lead.notes}
                        </textarea>
                    </div>
                ) : null
            }
        </div>
    );
};

HomeCard.propTypes = {
    homes: PropTypes.any,
};

HomeCard.defaultProps = {
    showLeads: false,
};

export default HomeCard;
