import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {Redirect, useParams} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAccount } from "../actions/auth.js";
import { getHomes, addHome, updateHome, deleteHome } from "../actions/homes.js";
import AddEditHome from 'components/AddEditHome/AddEditHome.jsx';

const AddEditHomeContainer = (props) => {
    const {
        userID,
        authenticated,
        loadingAuth,
        subscribed,
        getHomes,
        addHome,
        updateHome,
        deleteHome,
        homes,
        isAddingOrUpdating,
        getAccount,
        defaultFollowUpMessage,
        defaultWelcomeMessage,
        history,
    } = props;

    const { homeID } = useParams();

    useEffect(() => {
        if (authenticated && userID) {
            getHomes(userID);
            getAccount(userID);
        }
    }, [authenticated]);

    if (loadingAuth && !authenticated) return null;
    if (!loadingAuth && !authenticated) return <Redirect to="/" />;
    if (!loadingAuth && authenticated && !subscribed) return <Redirect to="/payment" />;
    return (
        <AddEditHome
            home={homeID && homes.find(home => home.homeID === homeID)}
            isUpdate={Boolean(homeID)}
            isAddingOrUpdating={isAddingOrUpdating}
            addHome={({title, link, address, image, welcomeMessage, followUpMessage}) => addHome({history, userID, title, link, address, image, welcomeMessage, followUpMessage})}
            updateHome={({title, link, address, image, welcomeMessage, followUpMessage}) => updateHome({history, userID, homeID, title, link, address, image, welcomeMessage, followUpMessage})}
            deleteHome={(homeID) => deleteHome(userID, homeID)}
            defaultWelcomeMessage={defaultWelcomeMessage}
            defaultFollowUpMessage={defaultFollowUpMessage}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userID: state.auth.userID,
        homes: state.homes.homes,
        isAddingOrUpdating: state.homes.isAddingOrUpdating,
        authenticated: state.auth.authenticated,
        subscribed: state.auth.subscribed,
        loadingAuth: state.auth.loading,
        defaultFollowUpMessage: state.auth.messages.defaultFollowUpMessage,
        defaultWelcomeMessage: state.auth.messages.defaultWelcomeMessage
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getHomes,
        addHome,
        updateHome,
        deleteHome,
        getAccount,
    }, dispatch);
};

AddEditHomeContainer.propTypes = {
    userID: PropTypes.string,
    subscribed: PropTypes.bool,
    loadingAuth: PropTypes.bool,
    addingOrUpdating: PropTypes.bool,
    addHome: PropTypes.func,
};

AddEditHomeContainer.defautProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditHomeContainer);
