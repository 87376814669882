import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Landing from 'components/Landing/Landing.jsx';

const LandingContainer = (props) => {
    const {
        authenticated,
    } = props;

    if (authenticated) return <Redirect to="/app/dashboard" />;
    return <Landing />;
};

const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};

LandingContainer.propTypes = {
    authenticated: PropTypes.bool.isRequired,
};

LandingContainer.defautProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingContainer);
