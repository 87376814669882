import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {config, useSpring, animated} from 'react-spring';
import { withFormik } from 'formik';
import logo from 'assets/OpenHomely_Logo_White.svg';
import AuthBg from 'components/AuthBg/AuthBg.jsx';
import Button from 'components/common/Button/Button.jsx';
import Modal from 'components/common/Modal/Modal.jsx';
import Dialog from 'components/common/Dialog/Dialog.jsx';
import 'components/ResetPassword/ResetPassword.scss';

const ResetPassword = ({
   handleSubmit,
   handleChange,
   handleBlur,
   values,
   touched,
   errors,
   error,
   hash,
}) => {

    const [isConfimationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const formAnimation = useSpring({
        to: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        from: {
            transform: 'translate(0%, 5%)',
            opacity: 0,
        },
        delay: 0,
        config: config.default
    });

    return (
        <>
            <AuthBg />
            <div className="ResetPassword">
                <div className="ResetPasswordWrapper">
                    <div className="ResetPasswordInner">
                        <Link to="/"><img className="ResetPasswordLogo" src={logo} alt="OpenHomely"/></Link>
                        <div className="ResetPasswordFormContainer" style={formAnimation} >
                            <div className="ResetPasswordFormTitle">Reset Password</div>
                            <form className="ResetPasswordForm" onSubmit={handleSubmit}>
                                <div className="ResetPasswordFormInputTitle">Email</div>
                                <input
                                    className="ResetPasswordFormInput"
                                    type="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    name="email"
                                />
                                {errors.email && touched.email && <div className="LoginFormInputFeedback">{errors.email}</div>}

                                { hash && (
                                    <>
                                        <div className="ResetPasswordFormInputTitle">New Password</div>
                                        <input
                                            className="ResetPasswordFormInput"
                                            type="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.newpassword}
                                            name="newpassword"
                                        />
                                        {errors.newpassword && touched.newpassword && <div className="LoginFormInputFeedback">{errors.newpassword}</div>}

                                        <div className="ResetPasswordFormInputTitle">Confirm New Password</div>
                                        <input
                                            className="ResetPasswordFormInput"
                                            type="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.confirmnewpassword}
                                            name="confirmnewpassword"
                                        />
                                        {errors.confirmnewpassword && touched.confirmnewpassword && <div className="LoginFormInputFeedback">{errors.confirmnewpassword}</div>}
                                    </>
                                )}

                                {submitted && error && error.response && <div className="ResetPasswordFormInputFeedback">{error.response.data}</div>}
                                <button className="ResetPasswordFormButton" type="submit" onClick={() => {
                                    setSubmitted(true);
                                    setIsConfirmationDialogOpen(true);
                                }}><Button title={hash ? "Reset Password" : "Request Reset"}/></button>
                                <div className="ResetPasswordFormForgotPassword"><Link to="/login">Login Instead</Link></div>
                            </form>
                        </div>
                    </div>
                </div>
                <Modal isOpen={isConfimationDialogOpen} handleClose={() => {}}>
                    <Dialog
                        title="Reset Password Request Received"
                        subtitle="Please check your email with further instructions on how to reset your password."
                        buttonTitle="Okay"
                        onClick={() => {
                            setIsConfirmationDialogOpen(false);
                            window.location = "/";
                        }}
                    />
                </Modal>
            </div>
        </>
    );
};

const ResetPasswordForm = withFormik({
    mapPropsToValues: () => ({ email: '', newpassword: '', confirmnewpassword: '' }),

    validate: (values, props) => {
        const errors = {};

        if (!values.email) {
            errors.email = 'Your email is required.';
        }

        if (!values.newpassword && props.hash) {
            errors.newpassword = 'Your new password is required.';
        }

        if (!values.confirmnewpassword && props.hash) {
            errors.confirmnewpassword = 'You must confirm your new password.';
        }

        if (!values.confirmnewpassword && props.hash) {
            errors.confirmnewpassword = 'Your new password must match.';
        }

        return errors;
    },

    handleSubmit: (values, { setSubmitting, props }) => {
        if (props.hash) {
            props.resetPassword(values.email, values.newpassword, props.hash);
        } else {
            props.resetPasswordRequest(values.email);
        }
        setSubmitting(false);
    },

    displayName: 'LoginForm',
})(ResetPassword);

ResetPassword.propTypes = {
    name: PropTypes.string,
    errors: PropTypes.any,
    handleSubmit: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
};

ResetPassword.defaultProps = {
    name: '',
};

export default ResetPasswordForm;

