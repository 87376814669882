import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import * as monaco from 'monaco-editor';
import "./Monaco.scss";

monaco.languages.register({ id: `openhomelylanguage` });

// Register a tokens provider for the language
monaco.languages.setMonarchTokensProvider(`openhomelylanguage`, {
    tokenizer: {
        root: [
            [/\[title]/, "custom-keyword"],
            [/\[link]/, "custom-keyword"],
            [/\[name]/, "custom-keyword"],
        ]
    }
});

// Define a new theme that contains only rules that match this language
monaco.editor.defineTheme('myCoolTheme', {
    base: 'vs',
    inherit: false,
    colors: {
        "editor.background": '#F0F9FF'
    },
    rules: [
        { token: 'custom-keyword', foreground: '0C74FF', fontStyle: 'bold' },
    ]
});

// Register a completion item provider for the new language
monaco.languages.registerCompletionItemProvider(`openhomelylanguage`, {
    provideCompletionItems: () => {
        var suggestions = [{
            label: '[home]',
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: '[home]'
        }, {
            label: '[title]',
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: '[title]'
        }, {
            label: '[link]',
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: '[link]'
        }];
        return { suggestions: suggestions };
    }
});

monaco.languages.registerHoverProvider(`openhomelylanguage`, {
    provideHover: function (model, position) {
        const word = model.getWordAtPosition(position);
        if (!word) return null;
        let content;
        switch(word.word) {
            case("title"):
                content = "The title of the home";
                break;
            case("link"):
                content = "The link for the home";
                break;
            case("name"):
                content = "The name of the lead this message will be sent to";
                break;
            default:
                break;
        }
        if (!content) return null;
        return {
            range: new monaco.Range(1, 1, model.getLineCount(), model.getLineMaxColumn(model.getLineCount())),
            contents: [
                { value: content },
            ]
        }
    }
});

const Monaco = ({className, id, name, onChange, onBlur, defaultValue}) => {

    useEffect(() => {
        let editor = monaco.editor.create(document.getElementById(id), {
            theme: 'myCoolTheme',
            value: defaultValue,
            language: `openhomelylanguage`,
            lineNumbers: 'on',
            lineDecorationsWidth: 0,
            lineNumbersMinChars: 0,
            minimap: {
                enabled: false
            },
            scrollbar: {
                scrollBeyondLastLine: false,
                alwaysConsumeMouseWheel: false,
            },
            scrollBeyondLastLine: false,
            editorClassName: "MonacoEditor",
        });

        // editor.scrollbar.alwaysConsumeMouseWheel = false;

        editor.onDidChangeModelContent(() => {
            const event = {
                persist: () => {},
                target: {
                    type: "change",
                    id: id,
                    name: name,
                    value: editor.getValue()
                }
            };
            onChange(event);
        });

        editor.onDidBlurEditorText(() => {
            const event = {
                persist: () => {},
                target: {
                    type: "blur",
                    id: id,
                    name: name,
                    value: editor.getValue()
                }
            };
            onBlur(event)
        })
    }, []);

    return (
        <div
            className={`${className} MonacoMount`}
            value={defaultValue}
            id={id}
        />
    )
};

Monaco.propTypes = {
    className: PropTypes.string,
    PropTypes: PropTypes.string,
    onChange: PropTypes.func,
};

Monaco.defaultProps = {
    className: "",
};

export default Monaco;
