import axios from 'axios';
import qs from 'qs';

export const login = (email, password) => {
    const url = '/auth/login';

    const requestData = {
        email,
        password,
    };

    const requestConfig = {
        data: qs.stringify(requestData),
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const tokenLogin = (jwt) => {
    const url = '/auth/tokenLogin';

    const requestData = {
        jwt
    };

    const requestConfig = {
        data: qs.stringify(requestData),
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const deleteAccount = () => {
    const url = '/auth/deleteaccount';

    const requestConfig = {
        method: 'delete',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const signup = (name, email, password) => {
    const url = '/auth/signup';

    const requestData = {
        name,
        email,
        password
    };

    const requestConfig = {
        data: qs.stringify(requestData),
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const resetPasswordRequest = (email) => {
    const url = '/auth/resetpasswordrequest';

    const requestData = {
        email
    };

    const requestConfig = {
        data: qs.stringify(requestData),
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const resetPassword = (email, password, hash) => {
    const url = '/auth/resetpassword';

    const requestData = {
        email,
        password,
        hash,
    };

    const requestConfig = {
        data: qs.stringify(requestData),
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const updateMessages = (userID, defaultWelcomeMessage, defaultFollowUpMessage) => {
    const url = `/users/${userID}/settings/messages`;

    const requestData = {
        defaultWelcomeMessage,
        defaultFollowUpMessage,
    };

    const requestConfig = {
        data: qs.stringify(requestData),
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const updateProfile = ({ userID, name, defaultWelcomeMessage, defaultFollowUpMessage, profileImage, emailSignature }) => {
    const url = '/users/' + userID;

    let formData = new FormData();
    formData.append("name", name);
    formData.append("defaultWelcomeMessage", defaultWelcomeMessage);
    formData.append("defaultFollowUpMessage", defaultFollowUpMessage);
    formData.append("image", profileImage);
    formData.append("emailSignature", emailSignature);

    const requestConfig = {
        data: formData,
        method: 'put',
        url,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        timeout: 30000
    };

    const response = axios(requestConfig);

    return response;
}

export const getAccount = (userID) => {
    const url = `/users/${userID}`;

    const requestConfig = {
        method: 'get',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const withdrawConsent = (email) => {
    const url = `/mail/withdrawconsent?email=${email}`;

    const requestConfig = {
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};
