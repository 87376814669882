import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {FaPlus, FaCog} from "react-icons/fa";
import {config, useSpring, animated} from "react-spring";
import Button from 'components/common/Button/Button.jsx';
import HR from 'components/common/HR/HR.jsx';
import 'components/Dashboard/Dashboard.scss';

const Dashboard = ({
    homes,
}) => {

    const subscriptionThankYouAnimation = useSpring({
        from: {
            transform: 'translateX(-25px)',
            opacity: 0,
            height: "0%",
        },
        to: {
            transform: 'translateX(0px)',
            opacity: 1,
            height: "100%",
        },
        delay: 250,
        config: config.gentle,
    });

    return (
        <div className="Dashboard">
            <div className="DashboardTop">
                <div className="DashboardTopLeft">
                    <div className="DashboardTitle">Dashboard</div>
                    <HR />
                </div>
                <Link to="/app/settings"><Button title="Settings" ButtonIcon={FaCog} outline/></Link>
            </div>
            <animated.div className="DashboardCard" style={subscriptionThankYouAnimation}>
                <div className="DashboardCardTitle">Welcome to OpenHomely! 🎉</div>
                <div className="DashboardCardSubtitle">
                    Thank you for subscribing
                </div>
            </animated.div>
            <div className="DashboardCard">
                <div className="DashboardCardTitle">
                    Homes
                    {/*<span className="DashboardCardTitle--description">The homes you have added to OpenHomely</span>*/}
                </div>
                <HR />
                <div className="DashboardCardSubtitle">
                    {
                        homes.length > 0
                            ? `You have ${homes.length} active homes on OpenHomely. To start an Open House, press 'Start Open House' on your desired home on the Homes page.`
                            : "You don't have any homes added yet. To add a home, press add home."
                    }
                </div>
                <Link to={homes.length > 0 ? "/app/homes" : "/app/homes/add"}><Button className="DashboardCardButton" title={homes.length > 0 ? "View Homes" : "Add Home"} ButtonIcon={homes.length > 0 ? null : FaPlus} outline /></Link>
            </div>
            <div className="DashboardCard">
                <div className="DashboardCardTitle">
                    Leads
                    {/*<span className="DashboardCardTitle--description">The people who have signed in to an open house</span>*/}
                </div>
                <HR />
                <div className="DashboardCardSubtitle">
                    {
                        homes.filter(h => h.leads && h.leads.filter(l => !l.follow_up_sent).length > 0).length > 0
                            ? `You have some Leads that have not been followed up with yet. Follow up with them by viewing your Leads on the Leads page.`
                            : (
                                homes.filter(h => h.leads && h.leads.length === 0).length === homes.length
                                    ? "You haven't collected any leads yet. To collect leads, add a home and start an Open House for it."
                                    : "All your leads are followed up with. View all your collected leads on the Leads page."
                            )
                    }
                </div>
                <Link to={homes.length > 0 ? "/app/leads" : "/app/homes/add"}><Button className="DashboardCardButton" title={homes.length > 0 ? "View Leads" : "Add Home"} ButtonIcon={homes.length > 0 ? null : FaPlus} outline /></Link>
            </div>
        </div>
        );
};

Dashboard.propTypes = {
    homes: PropTypes.any,
};

Dashboard.defaultProps = {
    homes: [],
};

export default Dashboard;
