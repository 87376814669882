import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import 'components/common/HR/HR.scss';

const HR = ({ className }) => <hr className={cx("HR", className)} />

HR.propTypes = {
    className: PropTypes.string,
};

export default HR;
