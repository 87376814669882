import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {FaFileUpload, FaCog} from "react-icons/fa";
import Button from 'components/common/Button/Button.jsx';
import Monaco from 'components/common/Monaco/Monaco.jsx';
import Modal from 'components/common/Modal/Modal.jsx';
import Dialog from 'components/common/Dialog/Dialog.jsx';
import HR from 'components/common/HR/HR.jsx';
import 'components/AddEditHome/AddEditHome.scss';

const AddHome = ({
     handleSubmit,
     handleChange,
     handleBlur,
     values,
     touched,
     errors,
     isUpdate,
     isAddingOrUpdating,
     setFieldValue,
 }) => (
    <div className="AddHomeContainer">
        <Modal isOpen={isAddingOrUpdating} handleClose={() => {}} showClose={false}>
            <Dialog
                title={isUpdate ? "Updating" : "Adding Home"}
                subtitle={isUpdate ? "Update in progress..." : "Adding Home in progress..."}
                buttonTitle=""
                showButton={false}
                showSpinner={true}
                onClick={() => {}}
            />
        </Modal>
        <div className="AddHome">
            <div className="AddHomeTop">
                <div className="AddHomeTopLeft">
                    <div className="AddHomeFormTitle">{ isUpdate ? "Update Home" : "Add Home" }</div>
                    <HR />
                </div>
                <Link to="/app/settings"><Button title="Settings" ButtonIcon={FaCog} outline/></Link>
            </div>
            <form className="AddHomeForm" onSubmit={handleSubmit} autocomplete="off">
                <div className="AddHomeFormColumn">
                    <div className="AddHomeFormItem">
                        <div className="AddHomeFormInputTitle">Title</div>
                        <input
                            className="AddHomeFormInput"
                            type="text"
                            placeholder="Title for this home"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            name="title"
                        />
                        {errors.title && touched.title && <div className="AddHomeFormInputFeedback">{errors.title}</div>}
                        <div className="AddHomeFormInputTitle">Address</div>
                        <input
                            className="AddHomeFormInput"
                            type="text"
                            placeholder="Address for this home"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address}
                            name="address"
                        />
                        {errors.address && touched.address && <div className="AddHomeFormInputFeedback">{errors.address}</div>}
                        <div className="AddHomeFormInputTitle">Link</div>
                        <input
                            className="AddHomeFormInput"
                            type="url"
                            placeholder="Link for this home"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.link}
                            name="link"
                        />
                        {errors.link && touched.link && <div className="AddHomeFormInputFeedback">{errors.link}</div>}
                    </div>
                    <div className="AddHomeFormItem">
                        <div className="AddHomeFormInputTitle">Image</div>
                        <label for="add-edit-home-image-input">
                            <Button className="AddHomeFormImageInputButton" title="Upload Image" ButtonIcon={FaFileUpload} outline />
                        </label>
                        <input
                            id="add-edit-home-image-input"
                            type="file"
                            accept="image/png, image/jpeg"
                            className="AddHomeFormImageInput"
                            onChange={e => setFieldValue("image", e.currentTarget.files[0])}
                            onBlur={e => setFieldValue("image", e.currentTarget.files[0])}
                            // value={typeof values.image === "object" ? values.image : values.image.name}
                            name="image"
                        />
                        <img src={typeof values.image === "object" && values.image !== null ? window.URL.createObjectURL(values.image) : values.image} className="AddHomeFormImage" />
                        {errors.image && touched.image && <div className="AddHomeFormInputFeedback">{errors.image}</div>}
                    </div>
                </div>
                <div className="AddHomeFormColumn">
                    <div className="AddHomeFormMonacoItem">
                        <div className="AddHomeFormInputTitle">Welcome Message</div>
                        <Monaco
                            className="AddHomeFormTextArea"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.welcomeMessage}
                            id="welcomeMessage"
                            name="welcomeMessage"
                        />
                        {errors.welcomeMessage && touched.welcomeMessage && <div className="AddHomeFormInputFeedback">{errors.welcomeMessage}</div>}
                    </div>
                    <div class="AddHomeFormMonacoDescription">
                        {/*<div className="AddHomeFormInputTitle">Keywords Reference</div>*/}
                        <div className="AddHomeFormMonacoDescriptionItem"><span className="AddHomeFormMonacoDescription--keyword">[name]</span> becomes the name of the lead who signed into this open house.</div>
                        <div className="AddHomeFormMonacoDescriptionItem"><span className="AddHomeFormMonacoDescription--keyword">[title]</span> becomes the title of this home.</div>
                        <div className="AddHomeFormMonacoDescriptionItem"><span className="AddHomeFormMonacoDescription--keyword">[address]</span> becomes the address of this home.</div>
                        <div className="AddHomeFormMonacoDescriptionItem"><span className="AddHomeFormMonacoDescription--keyword">[link]</span> becomes the link of this home.</div>
                    </div>
                    <div className="AddHomeFormMonacoItem">
                        <div className="AddHomeFormInputTitle">Follow-Up Message</div>
                        <Monaco
                            className="AddHomeFormTextArea"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.followUpMessage}
                            id="followUpMessage"
                            name="followUpMessage"
                        />
                        {errors.followUpMessage && touched.followUpMessage && <div className="AddHomeFormInputFeedback">{errors.followUpMessage}</div>}
                    </div>
                    <div class="AddHomeFormMonacoDescription">
                        {/*<div className="AddHomeFormInputTitle">Keywords Reference</div>*/}
                        <div className="AddHomeFormMonacoDescriptionItem"><span className="AddHomeFormMonacoDescription--keyword">[name]</span> becomes the name of the lead who signed into this open house.</div>
                        <div className="AddHomeFormMonacoDescriptionItem"><span className="AddHomeFormMonacoDescription--keyword">[title]</span> becomes the title of this home.</div>
                        <div className="AddHomeFormMonacoDescriptionItem"><span className="AddHomeFormMonacoDescription--keyword">[address]</span> becomes the address of this home.</div>
                        <div className="AddHomeFormMonacoDescriptionItem"><span className="AddHomeFormMonacoDescription--keyword">[link]</span> becomes the link of this home.</div>
                    </div>
                </div>
                <button className="AddHomeFormButton" type="submit"><Button title={isUpdate ? "Update Home" : "Add Home"} /></button>
            </form>
        </div>
    </div>
);

const AddHomeForm = withFormik({
    mapPropsToValues: (props) => {
        return props.isUpdate ? {
            title: props.home.title,
            address: props.home.address,
            link: props.home.link,
            image: props.home.image,
            welcomeMessage: props.home.messages.welcomeMessage,
            followUpMessage: props.home.messages.followUpMessage
        } : { title: '', address: '', link: '', image: '', welcomeMessage: props.defaultWelcomeMessage, followUpMessage: props.defaultFollowUpMessage };
    },

    validate: values => {
        const errors = {};

        if (!values.title && (values.welcomeMessage.includes('[title]') || values.followUpMessage.includes('[title]'))) {
            errors.title = 'A title is required.';
        }

        if (!values.address && (values.welcomeMessage.includes('[address]') || values.followUpMessage.includes('[address]'))) {
            errors.address = 'An address is required.';
        }

        if (!values.link && (values.welcomeMessage.includes('[link]') || values.followUpMessage.includes('[link]'))) {
            errors.link = 'A link is required.';
        }

        return errors;
    },

    handleSubmit: (values, { setSubmitting, props }) => {
        if (props.isUpdate) {
            props.updateHome({
                title: values.title,
                link: values.link,
                address: values.address,
                image: values.image,
                welcomeMessage: values.welcomeMessage,
                followUpMessage: values.followUpMessage,
            })
        } else {
            props.addHome({
                title: values.title,
                link: values.link,
                address: values.address,
                image: values.image,
                welcomeMessage: values.welcomeMessage,
                followUpMessage: values.followUpMessage,
            })
        }
        setSubmitting(false);
    },

    validateOnChange: false,
    validateOnBlur: false,
    displayName: 'AddHomeForm',
})(AddHome);

AddHome.propTypes = {
    name: PropTypes.string,
    errors: PropTypes.any,
    handleSubmit: PropTypes.func.isRequired,
    isUpdate: PropTypes.bool,
    updateHome: PropTypes.func.isRequired,
    addHome: PropTypes.func.isRequired,
    title: PropTypes.string,
    link: PropTypes.string,
    welcomeMessage: PropTypes.string,
    followUpMessage: PropTypes.string,
    defaultWelcomeMessage: PropTypes.string,
    defaultFollowUpMessage: PropTypes.string,
    home: PropTypes.any,
};

AddHome.defaultProps = {
    isUpdate: false,
    name: '',
    title: '',
    address: '',
    link: ''
};

export default (AddHomeForm);
