import React from 'react';
import 'components/WithdrawConsent/WithdrawConsent.scss';

const WithdrawConsent = () => {
    return (
        <div className="WithdrawConsent">
            You will no longer receive communication from OpenHomely
        </div>
    );
};

WithdrawConsent.propTypes = {};

export default WithdrawConsent;
