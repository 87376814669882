import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/OpenHomely_Logo_White.svg';
import Button from 'components/common/Button/Button.jsx';
import HR from 'components/common/HR/HR.jsx';
import AuthBg from 'components/AuthBg/AuthBg.jsx';
import Modal from 'components/common/Modal/Modal.jsx';
import Dialog from 'components/common/Dialog/Dialog.jsx';
import { withFormik } from 'formik';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import './Payment.scss';

let autocomplete = null;

const Payment = ({
    unsubscribedReason,
    stripe_payment_intent_client_secret,
    stripe_payment_method,
    success,
    loading,
    logout,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    subscribe,
    values,
}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState("");

    const handlePlaceSelect = () => {
        let addressObject = autocomplete.getPlace();
        let address = addressObject.address_components;

        if (!address) return;

        var componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            postal_code: 'short_name'
        };

        var formatted = {};

        for (var i = 0; i < address.length; i++) {
            var addressType = address[i].types[0];
            if (componentForm[addressType]) {
                var val = address[i][componentForm[addressType]];
                formatted[addressType] = val;
            }
        }

        const event = {
            persist: () => {},
            target: {
                type: "change",
                id: "address-input",
                name: "address",
                value: {
                    name: addressObject.name,
                    street: Boolean(formatted.street_number && formatted.route) ? `${formatted.street_number} ${formatted.route}` : null,
                    city: formatted.locality ? formatted.locality : null,
                    state: formatted.administrative_area_level_1 ? formatted.administrative_area_level_1 : null,
                    zip: formatted.postal_code ? formatted.postal_code : null,
                    country: formatted.country ? formatted.country: null,
                    googleMapLink: addressObject.url
                }
            }
        };

        handleChange(event)
    };

    useEffect(() => {
        // eslint-disable-next-line
        autocomplete = new google.maps.places.Autocomplete(document.getElementById('payment-address'), {});
        autocomplete.addListener("place_changed", handlePlaceSelect);

        return () => autocomplete = null;
    }, []);

    useEffect(() => {
        console.log('hook update', stripe_payment_intent_client_secret, stripe_payment_method);
        if (!stripe || !stripe_payment_intent_client_secret || !stripe_payment_method) return;

        stripe.confirmCardSetup(stripe_payment_intent_client_secret, {
            payment_method: stripe_payment_method,
        }).then((result) => {
            if (result.error) {
                // start code flow to handle updating the payment details
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc)
                throw result;
            } else {
                if (result.setupIntent.status === 'succeeded') {
                   // There's a risk of the customer closing the window before callback
                   // execution. To handle this case, set up a webhook endpoint and
                   // listen to setup_intent.succeeded.
                   // return {
                   //     priceId: priceId,
                   //     subscription: subscription,
                   //     invoice: invoice,
                   //     paymentMethodId: paymentMethodId,
                   // };
                }
            }
        });
    }, [stripe_payment_intent_client_secret, stripe_payment_method, stripe]);

    const handleFormSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (!paymentMethod) return;

        if (error) {
            setErrorMessage(error.toString());
        } else {
            subscribe({
                paymentMethodID: paymentMethod.id,
                country: values.address.country,
                zip: values.address.zip,
                state: values.address.state,
                city: values.address.city,
                street: values.address.street
            });
        }
    };

    const CARD_OPTIONS = {
        iconStyle: 'solid',
        style: {
            base: {
                width: '100%',
                iconColor: '#c4f0ff',
                color: '#fff',
                fontWeight: 500,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {color: '#fce883'},
                '::placeholder': {color: '#87bbfd'},
                borderRadius: '1rem'
            },
            invalid: {
                iconColor: '#ffc7ee',
                color: '#ffc7ee',
            },
        },
    };

    return (
        <>
            <AuthBg />
            <Modal isOpen={loading} handleClose={() => {}} showClose={false}>
                <Dialog
                    title={"Just hang tight"}
                    subtitle={"We're preparing your new account..."}
                    buttonTitle=""
                    showButton={false}
                    showSpinner={true}
                    onClick={() => {}}
                />
            </Modal>
            <div className="Payment">
                <div className="PaymentWrapper">
                    <div className="PaymentInner">
                        <img className="PaymentLogo" src={logo} alt="OpenHomely"/>
                        <div className="PaymentFormWrapper">
                            <div className="PaymentTitle">{success ? "Sit tight, we're preparing your account" : "Subscribe to OpenHomely"}</div>
                            <HR className="PaymentHR" />
                            <div className="PaymentPricing">
                                28$ per month for your first 500 messages sent, 0.15$ each message sent thereafter, plus applicable taxes.
                                <br />
                                Prices in USD. Cancel Anytime.
                            </div>
                            { unsubscribedReason &&
                                <div className="PaymentUnsubscribedReasonWrapper">
                                    {(() => {
                                        if (unsubscribedReason === "subscription_deleted") {
                                            return <div className="PaymentUnsubscribedReason">Your previous Subscription was deleted. Please resubscribe!</div>
                                        } else if (unsubscribedReason === "subscription_canceled") {
                                            return <div className="PaymentUnsubscribedReason">Your previous Subscription was canceled due to either your request or an unpaid invoice. Please resubscribe to continue using OpenHomely!</div>
                                        } else if (unsubscribedReason === "payment_past_due") {
                                            return <div className="PaymentUnsubscribedReason">Your previous Subscription was canceled due to either your request or an unpaid invoice. Please resubscribe to continue using OpenHomely!</div>
                                        } else if (unsubscribedReason === "payment_failed") {
                                            return <div className="PaymentUnsubscribedReason">Your payment failed. Please try another method of payment!</div>
                                        }
                                    })()}
                                </div>
                            }
                            {/*<div className="PaymentCTA">Join a new era of tech focused Real Estate professionals</div>*/}
                            <form className="PaymentForm" onSubmit={handleFormSubmit}>
                                <fieldset className="PaymentFormGroup">
                                    <div className="PaymentFormInputTitle">Billing Address</div>
                                    <HR className="PaymentFormHR" />
                                    <input
                                        id="payment-address"
                                        className="PaymentFormInput"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="address"
                                    />
                                    {errors.address && touched.address && <div className="PaymentFormInputFeedback">{errors.address}</div>}
                                    <div className="PaymentFormInputTitle">Payment Information</div>
                                    <HR className="PaymentFormHR" />
                                    <CardElement
                                        options={CARD_OPTIONS}
                                    />
                                    {errorMessage && <div className="PaymentFormInputFeedback" id="card-errors" role="alert">{errorMessage}</div>}
                                </fieldset>
                                {/*<div id="card-errors" role="alert">{errorMessage}</div>*/}
                                <button className="PaymentButton" type="submit" disabled={!stripe}>
                                    <Button title="Subscribe" />
                                </button>
                                <div className="PaymentLogout" onClick={logout}>Logout</div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};


const PaymentForm = withFormik({
    mapPropsToValues: () => ({ address: {}, paymentMethodID: '' }),

    validate: values => {
        const errors = {};

        if (!values.address) {
            errors.address = 'Your billing address is required. Please select one of the autocomplete options that closest matches your street address.';
        }

        if (values.address && !(values.address.country === "US")) {
            errors.address = 'Sorry, but OpenHomely is only available in the US at the moment. Please select one of the autocomplete options that closest matches your street address.';
        }


        if (!values.paymentMethodID) {
            errors.paymentMethodID = 'You must supply a valid payment method.'
        }

        return errors;
    },

    handleSubmit: (values, { setSubmitting, props }) => {
        setSubmitting(false);
    },

    displayName: 'PaymentForm',
})(Payment);

Payment.propTypes = {
    success: PropTypes.bool,
    loading: PropTypes.bool,
    startPaymentSession: PropTypes.func,
    subscribe: PropTypes.func,
    logout: PropTypes.func,
};

Payment.defaultProps = {
    name: '',
};

export default PaymentForm;
