import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ScrollToTop from 'components/common/ScrollToTop/ScrollToTop.jsx';
import BaseRoutesContainer from 'containers/BaseRoutesContainer.jsx';
import AuthContainer from 'containers/AuthContainer.jsx';
import {
    BrowserRouter as Router,
    withRouter,
} from "react-router-dom";
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import dotenv from 'dotenv';
import path from 'path';
import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './reducers/rootReducer.js';
import configureAxios from './config/configureAxios.js';
import { createBrowserHistory } from 'history';

if (process.env.NODE_ENV === "development") {
    dotenv.config({ path: path.resolve(__dirname, '../.env.development') });
} else if (process.env.NODE_ENV === "staging") {
    dotenv.config({ path: path.resolve(__dirname, '../.env.development') });
} else {
    dotenv.config({ path: path.resolve(__dirname, '../.env.production') });
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory();

history.listen((location) => {
    window.ga('set', 'page', location.pathname + location.search);
    window.ga('send', 'pageview');
});

const store = createStore(
    rootReducer,
    {},
    composeEnhancers(
        applyMiddleware(
            ReduxThunk,
        )
    )
);

configureAxios();

ReactDOM.render(
    <Provider store={store} history={history}>
        <div style={{ fontFamily: "'Roboto', sans-serif"}}>
            <Router>
                <ScrollToTop />
                <AuthContainer>
                    <BaseRoutesContainer />
                </AuthContainer>
            </Router>
        </div>
    </Provider>
, document.getElementById('root'));
