import { combineReducers } from 'redux';
import authReducer from 'reducers/authReducer.js';
import homeReducer from 'reducers/homesReducer.js';
import paymentReducer from 'reducers/paymentReducer.js';

const rootReducer = combineReducers({
    auth: authReducer,
    homes: homeReducer,
    payment: paymentReducer,
});

export default rootReducer;
