import {
    START_PAYMENT_SESSION_REQUEST,
    START_PAYMENT_SESSION_SUCCESS,
    START_PAYMENT_SESSION_ERROR,
    UNSUBSCRIBE_REQUEST,
    UNSUBSCRIBE_SUCCESS,
    UNSUBSCRIBE_ERROR,
    SUBSCRIBE_REQUEST,
    SUBSCRIBE_SUCCESS,
    SUBSCRIBE_ERROR,
    CREATE_STRIPE_PORTAL_SESSION_REQUEST,
    CREATE_STRIPE_PORTAL_SESSION_SUCCESS,
    CREATE_STRIPE_PORTAL_SESSION_ERROR,
} from 'actions/payment.js';

export const initialState = {
    sessionID: '',
    loading: false,
    success: false,
    error: null,
};

export default function paymentReducer(state = initialState, action = {}) {
    switch (action.type) {
        case START_PAYMENT_SESSION_REQUEST:
        case UNSUBSCRIBE_REQUEST:
        case SUBSCRIBE_REQUEST:
        case CREATE_STRIPE_PORTAL_SESSION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            };
        case START_PAYMENT_SESSION_ERROR:
        case UNSUBSCRIBE_ERROR:
        case SUBSCRIBE_ERROR:
        case CREATE_STRIPE_PORTAL_SESSION_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.error,
            };
        case START_PAYMENT_SESSION_SUCCESS:
            return {
                ...state,
                sessionID: action.payload.data.sessionID,
                loading: false,
                success: true,
                error: null,
            };
        case UNSUBSCRIBE_SUCCESS:
            return initialState;
        case SUBSCRIBE_SUCCESS:
            return {
                loading: false,
                success: true,
                error: null
            };
        case CREATE_STRIPE_PORTAL_SESSION_SUCCESS:
            window.location.href = action.payload.data.url;
            
            return {
                loading: false,
                success: true,
                error: null
            };
        default:
            return state;
    }
}
