import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import queryString from 'query-string'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getHomes, sendFollowUp, deleteLeadByHome, deleteHome, sendFollowUpAll } from "../actions/homes.js";
import Leads from 'components/Leads/Leads.jsx';

const LeadsContainer = (props) => {
    const {
        userID,
        authenticated,
        loadingAuth,
        homes,
        getHomes,
        sendFollowUp,
        sendFollowUpAll,
        deleteLeadByHome,
        deleteHome,
        location,
    } = props;

    const { homeID } = queryString.parse(location.search);

    useEffect(() => {
        if (authenticated && userID) {
            getHomes(userID);
        }
    }, [authenticated]);

    if (loadingAuth && !authenticated) return null;
    if (!loadingAuth && !authenticated) return <Redirect to="/" />;

    return <Leads
        homes={homes}
        homeID={homeID}
        deleteLeadByHome={(leadID, homeID) => deleteLeadByHome(userID, leadID, homeID)}
        sendFollowUpAll={(homeID) => sendFollowUpAll(userID, homeID)}
        sendFollowUp={(leadID, homeID) => sendFollowUp(userID, homeID, leadID)}
        deleteHome={(homeID) => deleteHome(userID, homeID)}
    />;
};

const mapStateToProps = (state) => {
    return {
        userID: state.auth.userID,
        homes: state.homes.homes,
        authenticated: state.auth.authenticated,
        loadingAuth: state.auth.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getHomes,
        sendFollowUp,
        deleteLeadByHome,
        deleteHome,
        sendFollowUpAll,
    }, dispatch);
};

LeadsContainer.propTypes = {
    userID: PropTypes.string,
    homeID: PropTypes.string,
    loadingAuth: PropTypes.bool,
};

LeadsContainer.defautProps = {
    userID: '',
    homeID: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsContainer);
