import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import { withdrawConsent } from "../actions/auth";
import WithdrawConsent from 'components/WithdrawConsent/WithdrawConsent.jsx';

const WithdrawConsentContainer = (props) => {
    const {
        withdrawConsent,
        location
    } = props;

    const { email } = queryString.parse(location.search);

    useEffect(() => {
        if (email) {
            withdrawConsent(email)
        }
    }, []);

    return <WithdrawConsent />;
};

const mapStateToProps = (state) => {
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        withdrawConsent,
    }, dispatch);
};

WithdrawConsentContainer.propTypes = {
    withdrawConsent: PropTypes.func.isRequired,
};

WithdrawConsentContainer.defautProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawConsentContainer);
