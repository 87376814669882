import React from 'react';
import PropTypes from 'prop-types';
import {
    Switch,
    Route,
    useParams,
    Redirect,
} from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Landing from 'containers/LandingContainer.jsx';
import Login from 'containers/LoginContainer.jsx';
import Signup from 'containers/SignupContainer.jsx';
import AppRoutesContainer from 'containers/AppRoutesContainer.jsx'
import Payment from 'containers/PaymentContainer.jsx';
import ResetPassword from 'containers/ResetPasswordContainer.jsx';
import Terms from 'components/Terms/Terms.jsx';
import Privacy from 'components/Privacy/Privacy.jsx';
import SMS from 'components/SMS/SMS.jsx';
import WithdrawConsent from 'containers/WithdrawConsentContainer';
import OpenHouse from 'containers/OpenHouseContainer';

const BaseRoutesContainer = () => (
    <Switch>
        <Route path="/" component={Landing} exact />
        <Route path="/payment" component={Payment} />
        <Route path="/login" component={Login} exact />
        <Route path="/signup" component={Signup} exact />
        <Route path="/resetpassword" component={ResetPassword} exact />
        <Route path="/terms" component={Terms} exact />
        <Route path="/privacy" component={Privacy} exact />
        <Route path="/sms" component={SMS} exact />
        <Route path="/app" component={AppRoutesContainer} />
        <Route path="/withdrawconsent" component={WithdrawConsent} />
        <Route path="/v/:homeID" component={(routeProps) => <OpenHouse {...routeProps} isVirtual={true} />} />
        <Redirect to="/" />
    </Switch>
);

const mapStateToProps = (state) => {
    return {
        name: state.auth.name,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};

BaseRoutesContainer.propTypes = {
    name: PropTypes.string,
};

BaseRoutesContainer.defautProps = {
    name: ""
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseRoutesContainer);
