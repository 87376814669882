import axios from 'axios';
import qs from 'qs';

export const unsubscribe = () => {
    const url = '/payment/unsubscribe';

    const requestConfig = {
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const startPaymentSession = () => {
    const url = '/payment/startsession';

    const requestConfig = {
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const subscribe = ({ paymentMethodID, country, zip, state, city, street }) => {
    const url = '/payment/subscribe';

    const requestData = {
        paymentMethodID,
        country,
        zip,
        state,
        city,
        street
    };

    const requestConfig = {
        method: 'post',
        data: qs.stringify(requestData),
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const createStripePortalSession = () => {
    const url = '/payment/createstripeportalsession';

    const requestConfig = {
        method: 'post',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};
