import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getHomes, captureLeadByHome, getVirtualHome, captureVirtualLeadByHome } from "../actions/homes.js";
import OpenHouse from 'components/OpenHouse/OpenHouse.jsx';

const OpenHomeContainer = (props) => {
    const {
        userID,
        authenticated,
        loadingAuth,
        loadingHomes,
        homes,
        getHomes,
        getVirtualHome,
        captureLeadByHome,
        captureVirtualLeadByHome,
        isVirtual,
    } = props;

    const { homeID } = useParams();

    useEffect(() => {
        if (authenticated && userID && !isVirtual) {
            getHomes(userID);
        } else if (isVirtual) {
            getVirtualHome(homeID);
        }
    }, [authenticated]);

    let house = homes.find((home) => home.homeID === homeID);

    if (loadingAuth && !authenticated && !isVirtual) return null;
    if (!loadingAuth && !authenticated && !isVirtual) return <Redirect to="/" />;
    return (
        <OpenHouse
            loadingHomes={loadingHomes}
            isVirtual={isVirtual}
            house={house}
            captureLeadByHome={(name, email, phone) => captureLeadByHome(userID, homeID, name, email, phone)}
            captureVirtualLeadByHome={(name, email, phone) => captureVirtualLeadByHome(homeID, name, email, phone)}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userID: state.auth.userID,
        homes: state.homes.homes,
        authenticated: state.auth.authenticated,
        loadingAuth: state.auth.loading,
        loadingHomes: state.homes.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getVirtualHome,
        getHomes,
        captureLeadByHome,
        captureVirtualLeadByHome
    }, dispatch);
};

OpenHomeContainer.propTypes = {
    userID: PropTypes.string,
    homeID: PropTypes.string,
    loadingAuth: PropTypes.bool,
    loadingHomes: PropTypes.bool,
    addHome: PropTypes.func.isRequired,
    captureLeadByHome: PropTypes.func.isRequired,
};

OpenHomeContainer.defautProps = {
    userID: '',
    homeID: '',
    isVirtual: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenHomeContainer);
