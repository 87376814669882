
export const searchLeads = (homes, searchQuery, homeID) => {
    return homes.filter((home) => (
        (
            home.title && home.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            home.address && home.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
            home.link && home.link.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (
                    home.leads.length > 0 && (
                        home.leads.filter(lead => (
                            lead.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            lead.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            lead.email.toLowerCase().includes(searchQuery.toLowerCase())
                        )).length > 0
                    )
                )
        ) && (
            homeID ? home.homeID === homeID : true
        )
    ))
};

