import React from 'react';
import PropTypes from 'prop-types';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import ScrollToTop from 'components/common/ScrollToTop/ScrollToTop.jsx'
import 'components/App/App.scss';

const App = ({children}) => {
    return (
        <div className="App">
            <div className="AppContent">
                <Sidebar/>
                <ScrollToTop divId="AppScroll"/>
                <div className="AppScroll" id="AppScroll">
                    {children}
                </div>
                <div className="AppBGBar" />
            </div>
        </div>
    );
};

App.propTypes = {
    children: PropTypes.any,
};

export default App;
