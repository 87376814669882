import React, { useEffect, useMemo } from 'react';
import {
    Switch,
    Route,
} from "react-router-dom";
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tokenLogin, logout } from 'actions/auth.js';
import { startPaymentSession, subscribe } from 'actions/payment.js';
import Payment from 'components/Payment/Payment.jsx';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentContainer = (props) => {
    const {
        userID,
        jwt,
        authenticated,
        loadingAuth,
        subscribed,
        tokenLogin,
        logout,
        loadingSubscription,
        subscribe,
        stripe_payment_intent_client_secret,
        stripe_payment_method,
        unsubscribedReason
    } = props;


    let timeout;

    useEffect(() => {
        if (authenticated && jwt && !subscribed) {
            timeout = setInterval(() => tokenLogin(jwt), 3000);
        }
        return () => timeout && clearInterval(timeout);
    }, [subscribed]);

    const PaymentComponent = useMemo(() => (
        <Switch>
            <Route path="/payment"
               component={() =>
                    <Elements stripe={stripePromise}>
                        <Payment
                            stripe_payment_intent_client_secret={stripe_payment_intent_client_secret}
                            stripe_payment_method={stripe_payment_method}
                            loading={loadingSubscription}
                            startPaymentSession={startPaymentSession}
                            unsubscribedReason={unsubscribedReason}
                            subscribe={subscribe}
                            logout={logout}
                        />
                    </Elements>
               }
               exact
            />
        </Switch>
    ), [loadingSubscription, unsubscribedReason, stripe_payment_intent_client_secret, stripe_payment_method]);

    if (loadingAuth && !authenticated) return null;
    if (!loadingAuth && !authenticated) return <Redirect to="/" />;
    if (!loadingAuth && authenticated && subscribed) return <Redirect to="/app/dashboard" />;

    return PaymentComponent;
};

const mapStateToProps = (state) => {
    return {
        userID: state.auth.userID,
        jwt: state.auth.jwt,
        authenticated: state.auth.authenticated,
        subscribed: state.auth.subscribed,
        loadingAuth: state.auth.loading,
        unsubscribedReason: state.auth.unsubscribedReason,
        stripe_payment_intent_client_secret: state.auth.payment.stripe_payment_intent_client_secret,
        stripe_payment_method: state.auth.payment.stripe_payment_method,
        loadingSubscription: state.payment.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        tokenLogin,
        startPaymentSession,
        subscribe,
        logout,
    }, dispatch);
};

PaymentContainer.propTypes = {
    userID: PropTypes.string,
    jwt: PropTypes.string,
    subscribed: PropTypes.bool,
    loadingAuth: PropTypes.bool,
    loadingSubscription: PropTypes.bool,
    tokenLogin: PropTypes.func,
};

PaymentContainer.defautProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);

