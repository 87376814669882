import axios from 'axios';
import qs from 'qs';

export const getHomes = (userID) => {
    const url = '/users/' + userID + '/homes';

    const requestConfig = {
        method: 'get',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const getVirtualHome = (homeID) => {
    const url = '/virtual/' + homeID;

    const requestConfig = {
        method: 'get',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const deleteHome = (userID, homeID) => {
    const url = '/users/' + userID + /homes/ + homeID;

    const requestConfig = {
        method: 'delete',
        url,
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const addHome = ({userID, title, link, address, image, welcomeMessage, followUpMessage}) => {
    const url = '/users/' + userID + '/homes';

    let formData = new FormData();
    formData.append("title", title);
    formData.append("link", btoa(link));
    formData.append("address", address);
    formData.append("welcomeMessage", welcomeMessage);
    formData.append("followUpMessage", followUpMessage);
    formData.append("image", image);

    const requestConfig = {
        data: formData,
        method: 'post',
        url,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        timeout: 30000
    };

    const response = axios(requestConfig);

    return response;
};

export const updateHome = ({userID, homeID, title, link, address, image, welcomeMessage, followUpMessage}) => {
    const url = '/users/' + userID + /homes/ + homeID;

    let formData = new FormData();
    formData.append("title", title);
    formData.append("link", btoa(link));
    formData.append("address", address);
    formData.append("welcomeMessage", welcomeMessage);
    formData.append("followUpMessage", followUpMessage);
    formData.append("image", image);

    const requestConfig = {
        data: formData,
        method: 'put',
        url,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        timeout: 30000
    };

    const response = axios(requestConfig);

    return response;
};

export const captureLeadByHome = (userID, homeID, name, email, phone) => {
    const url = '/users/' + userID + /homes/ + homeID + '/leads';

    const payload = {
        email,
        name,
        phone
    };

    const requestConfig = {
        data: qs.stringify(payload),
        method: 'post',
        url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const captureVirtualLeadByHome = (homeID, name, email, phone) => {
    const url = '/virtual/' + homeID + '/leads';

    const payload = {
        email,
        name,
        phone
    };

    const requestConfig = {
        data: qs.stringify(payload),
        method: 'post',
        url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const sendFollowUp = (userID, homeID, leadID) => {
    const url = '/users/' + userID + /homes/ + homeID + '/leads/followup';

    const payload = {
        leadID,
    };

    const requestConfig = {
        data: qs.stringify(payload),
        method: 'post',
        url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const sendFollowUpAll = (userID, homeID) => {
    const url = '/users/' + userID + /homes/ + homeID + '/leads/followupall';

    const payload = {};

    const requestConfig = {
        data: qs.stringify(payload),
        method: 'post',
        url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
};

export const updateLeadByHome = ({ userID, homeID, leadID, email, phone, name, notes }) => {
    const url = '/users/' + userID + /homes/ + homeID + '/leads/' + leadID;

    const payload = {
        email,
        phone,
        name,
        notes
    };

    const requestConfig = {
        data: qs.stringify(payload),
        method: 'put',
        url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
}

export const deleteLeadByHome = (userID, homeID, leadID) => {
    const url = '/users/' + userID + /homes/ + homeID + '/leads/' + leadID;

    const requestConfig = {
        method: 'delete',
        url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 2000
    };

    const response = axios(requestConfig);

    return response;
}
