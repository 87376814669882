import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {FaCog} from "react-icons/fa";
import Button from 'components/common/Button/Button.jsx';
import HR from 'components/common/HR/HR.jsx';
import HomeCard from 'components/HomeCard/HomeCard.jsx';
import { generateLeadsCSV } from 'utils/generateCSV.js';
import 'components/Lead/Lead.scss';

const Lead = ({
    home,
    lead,
    homeID,
    leadID,
    updateLead,
    sendFollowUp,
    sendFollowUpAll,
    deleteLeadByHome,
    deleteHome,
}) => {

    const leadAnimation = useSpring({
        to: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        from: {
            transform: 'translate(0%, 0%)',
            opacity: 0,
        },
        delay: 0,
        config: config.default
    });

    const buttonAnimation = useSpring({
        to: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        from: {
            transform: 'translate(-2%, 0%)',
            opacity: 0,
        },
        delay: 0,
        config: config.default
    });

    const titleAnimation = useSpring({
        to: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        from: {
            transform: 'translate(2%, 0%)',
            opacity: 0,
        },
        delay: 0,
        config: config.default
    });

    return (
        <div className="Lead">
            <div className="LeadTop">
                <div className="LeadTopLeft">
                    <div className="LeadTitle">Lead: {lead.name}</div>
                    <HR />
                </div>
                <Link to="/app/settings"><Button title="Settings" ButtonIcon={FaCog} outline/></Link>
            </div>
            <HomeCard home={home} lead={lead} updateLead={updateLead} sendFollowUp={sendFollowUp} sendFollowUpAll={sendFollowUpAll} deleteLeadByHome={deleteLeadByHome} deleteHome={deleteHome} showLead />
        </div>
    );
};

Lead.propTypes = {
    home: PropTypes.any,
};

export default Lead;
