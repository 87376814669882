import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'components/common/Button/Button.jsx';
import Dialog from 'components/common/Dialog/Dialog.jsx';
import Modal from 'components/common/Modal/Modal.jsx';
import HR from 'components/common/HR/HR.jsx';
import ProfileSettings from 'containers/ProfileSettingsContainer.jsx';
import 'components/Settings/Settings.scss';

const Settings = ({
    logout,
    unsubscribe,
    deleteAccount,
    createStripePortalSession,
    usage,
}) => {
    // const [unsubscribeModalOpen, setUnsubscribeModalOpen] = useState(false);
    const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

    return (
        <div className="Settings">
            <div className="SettingsTop">
                <div className="SettingsTopLeft">
                    <div className="SettingsTitle">Settings</div>
                    <HR />
                </div>
                <Link to="/app/dashboard"><Button title="View Dashboard" outline /></Link>
            </div>
            {/*<animated.section className="SettingsSection" style={settingSectionAnimation} >*/}
                {/*<div className="SettingsSectionTitle">Subscription</div>*/}
                {/*<div className="SettingsSectionList">*/}
                    {/*<Button className="SettingsButton" title="Cancel Subscription" onClick={() => setUnsubscribeModalOpen(true)} outline />*/}
                {/*</div>*/}
            {/*</animated.section>*/}
            {/*<Modal isOpen={unsubscribeModalOpen} handleClose={() => setUnsubscribeModalOpen(false)}>*/}
                {/*<Dialog*/}
                    {/*title="Are you sure?"*/}
                    {/*subtitle="By unsubscribing you will retain access to your account until the next billing cycle, at which you will lose access to your account until you resubscribe."*/}
                    {/*buttonTitle="Unsubscribe"*/}
                    {/*onClick={() => {*/}
                        {/*unsubscribe();*/}
                        {/*setUnsubscribeModalOpen(false);*/}
                    {/*}}*/}
                {/*/>*/}
            {/*</Modal>*/}
            <Modal isOpen={deleteAccountModalOpen} handleClose={() => setDeleteAccountModalOpen(false)}>
                <Dialog
                    title="Are you sure?"
                    subtitle="By deleting your account, your subscription will be cancelled,
                    all data associated with your account will be deleted and you will not be
                    refunded for any remaining time in your current billing cycle. This action cannot be undone."
                    buttonTitle="Delete Account"
                    onClick={() => {
                        deleteAccount();
                        setDeleteAccountModalOpen(false);
                    }}
                />
            </Modal>
            <section className="SettingsSection">
                <div className="SettingsSectionTitle">Profile</div>
                <div className="SettingsSectionList">
                   <ProfileSettings />
                </div>
            </section>
            <section className="SettingsSection" style={{ padding: 0 }}>
                <div className="SettingsSectionTitle">Usage</div>
                <div className="SettingsSectionList">
                    <div>Your usage this billing period is: {usage} messages.</div>
                </div>
            </section>
            <section className="SettingsSection">
                <div className="SettingsSectionTitle">Account</div>
                <div className="SettingsSectionList">
                    <Button className="SettingsButton" title="Logout" onClick={logout} />
                    <Button className="SettingsButton" title="Delete Account" onClick={() => setDeleteAccountModalOpen(true)} outline />
                    <Button className="SettingsButton" title="Manage Payment Details" onClick={() => createStripePortalSession()} outline />
                    <Link to="/resetpassword"><Button className="SettingsButton" title="Reset Password" outline /></Link>
                </div>
            </section>
        </div>
    );
};

Settings.propTypes = {
    logout: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
    usage: PropTypes.number.isRequired,
};

export default Settings;
