import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { signup } from "../actions/auth";
import Signup from 'components/Signup/Signup.jsx';

const SignupContainer = (props) => {
    const {
        signup,
        authenticated,
        error,
    } = props;

    if (authenticated) return <Redirect to="/app/dashboard" />;
    return <Signup signup={signup} error={error} />;
};

const mapStateToProps = (state) => {
    return {
        name: state.auth.name,
        email: state.auth.email,
        error: state.auth.error,
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        signup,
    }, dispatch);
};

SignupContainer.propTypes = {
    signup: PropTypes.func.isRequired,
};

SignupContainer.defautProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupContainer);
