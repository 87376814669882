import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import cx from "classnames";
import Button from 'components/common/Button/Button.jsx';
import login from 'assets/Login.png';
import text from 'assets/text.png';
import textHighlight from 'assets/text-highlight.png';
import safari from 'assets/safari.png';
import computer from 'assets/computer.jpg';
import virtual from 'assets/virtual.jpg';
import shake from 'assets/shake.jpg';
import phone from 'assets/phone.jpg';
import email from 'assets/email4.png';
import logo from 'assets/OpenHomely_Logo_White.svg';
import msgTemplate from 'assets/msgtemplate.png';
import msgTemplateResult from 'assets/msgtemplateresult.png';
import arrow from 'assets/arrow.png';
import url from 'assets/url.png';
import 'components/Landing/Landing.scss';
import { FaArrowRight, FaCheckCircle } from "react-icons/fa";
import {config, useSpring, animated} from "react-spring";

const scrollTo = (divId) => {
    const ele = document.getElementById(divId);
    window.scrollTo({
        top: ele.offsetTop,
        left: ele.offsetLeft,
        behavior: 'smooth'
    })
};

const Landing = () => {

    const textHighlightAnimation = useSpring({
        from: {
            transform: 'translateY(45px)',
            opacity: 0,
        },
        to: {
            transform: 'translateY(0px)',
            opacity: 1,
        },
        delay: 3000,
        config: config.gentle,
    });

    return (
        <div className="Landing">
            <header className="LandingHeader">
                <div className="LandingHeaderInner">
                    <div className="LandingHeaderInnerLeft">
                        <img className="LandingHeaderLogo" src={logo} alt="OpenHomely"/>
                        <div onClick={() => scrollTo("DigitalSignIn")}><span className="LandingHeaderLink">About</span></div>
                        <div onClick={() => scrollTo("Pricing")}><span className="LandingHeaderLink">Pricing</span></div>
                    </div>
                    <Link to="/login"><span className="LandingHeaderLogin">Login</span></Link>
                </div>
            </header>
            <section className="LandingSection LandingHero">
                <div className="LandingHeroBgImg"/>
                <div className="LandingHeroGutter LandingGutterPadding">
                    <div className="LandingHeroContentWrapper">
                        <div className="LandingHeroContentBg"/>
                        <div className="LandingHeroContentInner">
                            <div className="LandingHeroTitle">
                                Super simple Open House lead management.
                            </div>
                            <hr className="LandingSectionInfoHr"/>
                            <div className="LandingHeroSubtitle">
                                OpenHomely captures Open House Leads. It tracks leads,
                                automatically welcomes them by text and email,
                                and follows up with them in just one click. All while saving you
                                time so you can focus on what you do best.
                            </div>
                            <div className="LandingHeroSubtitle">Leverage the power of OpenHomely during your next Open House.</div>
                            <Link to="/signup"><Button className="LandingHeroSignUp" title="Sign Up" ButtonIcon={FaArrowRight} /></Link>

                        </div>
                        <animated.img className="LandingTextHighlight" style={textHighlightAnimation} src={textHighlight} />
                    </div>
                </div>
            </section>
            <section className="LandingSection">
                <div className="LandingSectionQuote LandingGutter LandingSectionInfo">
                    <b>"</b>OpenHomely is by far the best Open House lead collection tool I have ever used. It is super simple to set up, whether or not my Open House is a virtual event or in person it does exactly what I need it to do and saves me so much time!<b>"</b>
                    <hr className="LandingSectionQuoteHr"/>
                    <div className="LandingSectionQuoteAuthor">- Lisa, Broker/Owner HomeWorks Realty Ltd.</div>
                </div>
            </section>
            <div id="Features">
                <section className="LandingSection">
                    <div className="LandingSectionInfo LandingGutter">
                        <div className="LandingSectionInfoTitle LandingSectionInfoTitle--center">Features</div>
                        <hr className="LandingSectionInfoHr LandingSectionInfoHr--center"/>
                        <div className="LandingSectionInfoContent LandingSectionInfoContent--row">
                            <div className="LandingSectionInfoContent LandingSectionWhatWeDoInfoCard">
                                OpenHomely provides a suite of features that will augment your Real Estate business and delight your clients.
                                <Link to="/signup"><Button className="LandingHeroSignUp" title="Sign Up" ButtonIcon={FaArrowRight} /></Link>
                            </div>
                            <div className="LandingSectionInfoContent LandingSectionInfoContent--row  LandingSectionInfoContent--rowCenter LandingSectionInfoContent--rowSpaceBetween">
                                <div className="LandingSectionWhatWeDoCardWrapper" onClick={() => scrollTo("DigitalSignIn")}>
                                    <div className="LandingSectionWhatWeDoCardImg"
                                         style={{ backgroundImage: `url(${shake}` }}
                                    />
                                    <div className="LandingSectionWhatWeDoCardTitle">
                                        Digital Sign-In
                                    </div>
                                    <div className="LandingSectionWhatWeDoCardSubtitle">
                                        Say goodbye to paper sign-in sheets! OpenHomely signs in open house guests with its digital sign-in form.
                                    </div>
                                </div>
                                <div className="LandingSectionWhatWeDoCardWrapper" onClick={() => scrollTo("VirtualSignIn")}>
                                    <div className="LandingSectionWhatWeDoCardImg"
                                         style={{ backgroundImage: `url(${virtual}` }}
                                    />
                                    <div className="LandingSectionWhatWeDoCardTitle">
                                        Virtual Open House
                                    </div>
                                    <div className="LandingSectionWhatWeDoCardSubtitle">
                                        OpenHomely signs in and collects leads for guests of virtual open home events.
                                    </div>
                                </div>
                                <div className="LandingSectionWhatWeDoCardWrapper" onClick={() => scrollTo("DigitalLeadManagement")}>
                                    <div className="LandingSectionWhatWeDoCardImg"
                                         style={{ backgroundImage: `url(${computer}` }}
                                     />
                                    <div className="LandingSectionWhatWeDoCardTitle">
                                        Professional Lead Management
                                    </div>
                                    <div className="LandingSectionWhatWeDoCardSubtitle">
                                        Lead tracking, exporting, note taking and follow ups all at your fingertips.
                                    </div>
                                </div>
                                <div className="LandingSectionWhatWeDoCardWrapper" onClick={() => scrollTo("WelcomeAndFollowUp")}>
                                    <div className="LandingSectionWhatWeDoCardImg"
                                         style={{ backgroundImage: `url(${phone}` }}
                                     />
                                    <div className="LandingSectionWhatWeDoCardTitle">
                                        Welcome And Follow-Up Messages
                                    </div>
                                    <div className="LandingSectionWhatWeDoCardSubtitle">
                                        OpenHomely follows up with your guests by Text Message and Email.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div id="WhyOpenHomely">
                <section className="LandingSection LandingSection--bgblue">
                    <div className="LandingSectionInfo LandingGutter">
                        <div className="LandingSectionInfoTitle">Why OpenHomely?</div>
                        <hr className="LandingSectionInfoHr"/>
                        <div className="LandingSectionInfoContent LandingSectionInfoContent--row">
                            <div>
                                OpenHomely was made with one principle in mind: Real Estate professionals deserve super simple digital lead collection for their Open Homes.<br/><br/>
                                There are tons of Real Estate tools out there. They're almost always too complicated, time consuming, expensive and try to do too many things. OpenHomely believes your job should be in Real Estate, not in managing yet another tech platform.<br/><br/>
                                OpenHomely does one thing and does it well - it captures your leads and delights your Open House visitors. It gets the information they want into their hands, and the information you want into yours.<br/><br/>
                                This is not an advertising platform, this is not another tiresome media platform, this is not another massively complicated all-in-one solution. This is super simple lead collection made just for you.
                            </div>
                            <div className="LandingSectionWhyOpenHomelyInfoCard">
                                <div className="LandingSectionWhyOpenHomelyInfoCardTitle">
                                    OpenHomely believes your job should be in Real Estate, not in managing yet another tech platform.<br/><br/>
                                    This is super simple lead collection made just for you.
                                </div>
                                <Link to="/signup"><Button className="LandingHeroSignUp" title="Sign Up" ButtonIcon={FaArrowRight} /></Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div id="DigitalSignIn">
                <section className="LandingSection">
                    <div className="LandingSectionInfo LandingGutter">
                        <div className="LandingSectionInfoTitle">Digital Sign-in</div>
                        <hr className="LandingSectionInfoHr"/>
                        <div className="LandingSectionInfoContent LandingSectionInfoContent--row">
                            <div>
                                Put down the pen and say goodbye to paper sign in sheets!<br/><br/>
                                OpenHomely's digital sign in form allows you to collect your visitors information with
                                ease.<br/><br/>
                                Your guests will be delighted by your interest in technology,
                                and will be impressed by the premium experience you provide.<br/><br/>
                                OpenHomely works across all your devices so you can collect leads on your phone, tablet
                                or computer.<br/><br/>
                                <Link to="/signup"><Button className="LandingHeroSignUp" title="Sign Up" ButtonIcon={FaArrowRight} /></Link>
                            </div>
                            <img className="LandingSectionInfoLoginImg" src={login}/>
                        </div>
                    </div>
                </section>
            </div>
            <div id="VirtualSignIn">
                <section className="LandingSection LandingSection--bgblue">
                    <div className="LandingSectionInfo LandingGutter">
                        <div className="LandingSectionInfoTitle">Virtual Open House Sign-in</div>
                        <hr className="LandingSectionInfoHr"/>
                        <div className="LandingSectionInfoContent LandingSectionInfoContent--column">
                            <div>
                                OpenHomely generates a Virtual Open House link for each home you add to the platform. You can share this link in your advertisements and social media posts.<br/><br/>
                                When your guests visit the link, they'll sign-in to your open house using OpenHomely, after which they'll be redirected to whatever platform you have your virtual open house on.<br/><br/>
                                With OpenHomely you can see who's watching your Open House livestreams, YouTube videos, reading your Facebook posts and viewing your Open House content.
                            </div>
                            <img className="LandingSectionVirtualSignInImg" src={safari}/>
                        </div>
                    </div>
                </section>
            </div>
            <div id="DigitalLeadManagement">
                <section className="LandingSection">
                    <div className="LandingSectionInfo LandingGutter">
                        <div className="LandingSectionInfoTitle">Professional Lead Management</div>
                        <hr className="LandingSectionInfoHr"/>
                        <div className="LandingSectionInfoContent LandingSectionInfoContent--row">
                            <div>
                                Never again waste your time deciphering your visitor's handwriting and having to copy their information onto your computer.<br/><br/>
                                Since OpenHomely collects your leads digitally, OpenHomely manages them digitally as well.<br/><br/>
                                Easily follow up with your leads, search through them, take notes on them, and access their contact
                                information in a matter of seconds.<br/><br/>
                                OpenHomely even lets you export your leads so you can use them in other CRM programs that you use.
                            </div>
                            <div className="LandingSectionDigitalLeadManagementCard">
                                <span className="LandingSectionDigitalLeadManagementCardItem"><FaCheckCircle /> Lead Management</span><br/>
                                <span className="LandingSectionDigitalLeadManagementCardItem"><FaCheckCircle /> Lead Note Taking</span><br/>
                                <span className="LandingSectionDigitalLeadManagementCardItem"><FaCheckCircle /> Lead Search</span><br/>
                                <span className="LandingSectionDigitalLeadManagementCardItem"><FaCheckCircle /> Lead Exporting</span><br/>
                                <Link to="/signup"><Button className="LandingSectionDigitalLeadManagementCardSignUp" title="Sign Up" ButtonIcon={FaArrowRight} /></Link>
                            </div>
                        </div>
                    </div>
                    {/*<img className="LandingSectionInfoCircleShapes" src={circleShapes}/>*/}
                </section>
            </div>
            <div id="WelcomeAndFollowUp">
                <section className="LandingSection LandingSectionInfoWelcome LandingSection--bgblue">
                    <div className="LandingSectionInfo LandingGutter">
                        <div className="LandingSectionInfoTitle">Welcome And Follow-Up Messages</div>
                        <hr className="LandingSectionInfoHr"/>
                        <div className="LandingSectionInfoContent LandingSectionInfoContent--column">
                            OpenHomely texts and emails your visitors your
                            professional welcome message as soon as they sign in to your open house.<br/><br/>
                            In your message you can choose to include links to
                            the property's website or brochure as well as your contact information.
                            Never worry about your guests losing your info or a feature sheet again, since they'll have it on
                            their phone and in their email the moment they walk in.<br/><br/>
                            After your open house, you can send
                            a follow-up message by text and email thanking your visitors for coming in with just a press of
                            a button.
                        </div>
                        <div className="LandingSectionInfoContent--row">
                            <img className="LandingSectionInfoBubble" src={text}/>
                            <img className="LandingSectionInfoBubble" src={email}/>
                        </div>
                    </div>
                </section>
            </div>
            <div id="ProfessionalCommunication">
                <section className="LandingSection">
                    <div className="LandingSectionInfo LandingGutter">
                        <div className="LandingSectionInfoTitle">Personalized Communication</div>
                        <hr className="LandingSectionInfoHr"/>
                        <div className="LandingSectionInfoContent">
                            OpenHomely makes every message you send count by personalizing it for each recipient.<br/><br/>

                            OpenHomely lets you customize your message templates with OpenHomely's intuitive template editor. You can use your
                            default template, or customize a template specifically for an individual listing.<br/><br/>

                            Information collected from the OpenHomely sign-in form will be populated into the template when
                            a message is sent. It's intuitive, easy, and will delight your clients.
                        </div>

                        <div
                            className="LandingSectionPersonalCommunicationContent LandingSectionInfoContent--row LandingSectionInfoContent--rowCenter">
                            <img className="LandingSectionInfoBubble" src={msgTemplate}/>
                            <img className="LandingSectionPersonalCommunicationArrow" src={arrow}/>
                            <img className="LandingSectionInfoBubble" src={msgTemplateResult}/>
                        </div>
                    </div>
                </section>
            </div>
            <section className="LandingSection">
                <div className="LandingSectionHowItWorks LandingGutter">
                    <div className="LandingSectionInfoTitle LandingSectionInfoTitle--center">How it all works in
                        practice
                    </div>
                    <hr className="LandingSectionInfoHr LandingSectionInfoHr--center"/>
                    <div className="LandingSectionHowItWorksContent">
                        <div className="LandingSectionHowItWorksItem">
                            <div className="LandingSectionHowItWorksItemNumber">1</div>
                            Add your listing to your
                            OpenHomely account. It
                            only takes seconds, and
                            can be done right before
                            your Open House starts.
                        </div>
                        <div className="LandingSectionHowItWorksItem">
                            <div className="LandingSectionHowItWorksItemNumber">2</div>
                            Customize your welcome
                            and follow up message template
                            for your Open House, or choose to use your
                            default templates.
                        </div>
                        <div className="LandingSectionHowItWorksItem">
                            <div className="LandingSectionHowItWorksItemNumber">3</div>
                            Allow your visitors to sign in
                            using the OpenHomely sign in
                            form, and watch as they receive
                            a text message and email
                            with your welcome message.
                        </div>
                        <div className="LandingSectionHowItWorksItem">
                            <div className="LandingSectionHowItWorksItemNumber">4</div>
                            During your open house you can take notes
                            on your visitors using OpenHomely.
                            These notes will be available for you
                            after your open house as well.
                        </div>
                        <div className="LandingSectionHowItWorksItem">
                            <div className="LandingSectionHowItWorksItemNumber">5</div>
                            After your open house, send
                            your customized follow-up
                            message by text and email
                            thanking each lead for visiting in
                            just the press of a button.
                        </div>
                    </div>
                </div>
            </section>
            <div id="Pricing">
                <section className="LandingSection">
                    <div className="LandingSectionPricingStripe"/>
                    <div className="LandingSectionPricing LandingGutter">
                        <div className="LandingSectionPricingCard">
                            <div className="LandingSectionPricingCardTop">
                                <div className="LandingSectionPricingCardTitle">Just 28$/month</div>
                                <div className="LandingSectionPricingCardSubtitle">Taxes Additional. Cancel Anytime.
                                    Price in USD. Price is 28$/month for your first 500 messages sent, 0.15$ per additional
                                    message sent thereafter.
                                </div>
                            </div>
                            <div className="LandingSectionPricingCardFeatures">
                                <div className="LandingSectionPricingCardFeature">Premium Digital Sign-in Form</div>
                                <div className="LandingSectionPricingCardFeature">In Person Open House Sign-in</div>
                                <div className="LandingSectionPricingCardFeature">Virtual Open House Sign-in</div>
                                <div className="LandingSectionPricingCardFeature">Unlimited Homes</div>
                                <div className="LandingSectionPricingCardFeature">Lead Note Taking</div>
                                <div className="LandingSectionPricingCardFeature">Personalized Email Branding</div>
                                <div className="LandingSectionPricingCardFeature">Online Lead Management</div>
                                <div className="LandingSectionPricingCardFeature">Automatic Welcome & Follow Up Messages
                                </div>
                                <div className="LandingSectionPricingCardFeature">Welcome & Follow Ups by Text Message
                                </div>
                                <div className="LandingSectionPricingCardFeature">Welcome & Follow Ups by Email</div>
                            </div>
                            <div className="LandingSectionPricingCardBottom">
                                <Link to="/signup"> <Button className="LandingSectionPricingCardBottomButton" ButtonIcon={FaArrowRight} title="Sign Up"/> </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section className="LandingFooterSection">
                <div className="LandingFooter">
                    <div className="LandingFooterBgImg"/>
                    <div className="LandingFooterInner">
                        <div className="LandingFooterLeft">
                            <img className="LandingFooterLogo" src={logo} alt="OpenHomely"/>
                            <a href="mailto:support@openhomely.com"><span
                                className="LandingFooterEmail">support@openhomely.com</span></a>
                        </div>
                        <div className="LandingFooterRight">
                            <Link to="/terms"><span className="LandingFooterLink">Terms of Service</span></Link>
                            <Link to="/privacy"><span className="LandingFooterLink">Privacy Policy</span></Link>
                            <Link to=""><span className="LandingFooterLink">© 2020 OpenHomely Technologies Inc.</span></Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Landing;
