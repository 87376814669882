import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getHomes, sendFollowUp, updateLeadByHome, deleteLeadByHome, deleteHome, sendFollowUpAll } from "../actions/homes.js";
import Lead from 'components/Lead/Lead.jsx';

const LeadContainer = (props) => {
    const {
        userID,
        authenticated,
        loadingAuth,
        homes,
        getHomes,
        sendFollowUp,
        sendFollowUpAll,
        deleteLeadByHome,
        deleteHome,
        location,
        history
    } = props;

    const { homeID, leadID } = useParams();

    useEffect(() => {
        if (authenticated && userID) {
            getHomes(userID);
        }
    }, [authenticated]);

    if (loadingAuth && !authenticated ) return null;
    if (!loadingAuth && !authenticated) return <Redirect to="/" />;
    if (!homes.find(h => h.homeID === homeID)) return <Redirect to="/app/homes" />;
    if (!homes.find(h => h.homeID === homeID).leads.find(l => l._id === leadID)) return <Redirect to="/app/homes" />;

    return <Lead
        home={homes.find(h => h.homeID === homeID)}
        lead={homes.find(h => h.homeID === homeID).leads.find(l => l._id === leadID)}
        homeID={homeID}
        leadID={leadID}
        updateLead={({email, name, phone, notes}) => updateLeadByHome({userID, homeID, leadID, email, name, phone, notes})}
        sendFollowUp={(leadID, homeID) => sendFollowUp(userID, homeID, leadID)}
        sendFollowUpAll={(homeID) => sendFollowUpAll(userID, homeID)}
        deleteLeadByHome={(leadID, homeID) => deleteLeadByHome(userID, leadID, homeID, history)}
        deleteHome={(homeID) => deleteHome(userID, homeID)}
    />;
};

const mapStateToProps = (state) => {
    return {
        userID: state.auth.userID,
        homes: state.homes.homes,
        authenticated: state.auth.authenticated,
        loadingAuth: state.auth.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getHomes,
        sendFollowUp,
        sendFollowUpAll,
        deleteLeadByHome,
        deleteHome,
    }, dispatch);
};

LeadContainer.propTypes = {
    userID: PropTypes.string,
    homeID: PropTypes.string,
    loadingAuth: PropTypes.bool,
};

LeadContainer.defautProps = {
    userID: '',
    homeID: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadContainer);
