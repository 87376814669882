import {
    GET_HOMES_REQUEST,
    GET_HOMES_ERROR,
    GET_HOMES_SUCCESS,
    GET_VIRTUAL_HOME_REQUEST,
    GET_VIRTUAL_HOME_ERROR,
    GET_VIRTUAL_HOME_SUCCESS,
    ADD_HOME_REQUEST,
    ADD_HOME_ERROR,
    ADD_HOME_SUCCESS,
    DELETE_HOME_REQUEST,
    DELETE_HOME_ERROR,
    DELETE_HOME_SUCCESS,
    UPDATE_HOME_REQUEST,
    UPDATE_HOME_ERROR,
    UPDATE_HOME_SUCCESS,
    SEND_FOLLOW_UP_REQUEST,
    SEND_FOLLOW_UP_ERROR,
    SEND_FOLLOW_UP_SUCCESS,
    SEND_FOLLOW_UP_ALL_REQUEST,
    SEND_FOLLOW_UP_ALL_ERROR,
    SEND_FOLLOW_UP_ALL_SUCCESS,
    UPDATE_LEAD_REQUEST,
    UPDATE_LEAD_SUCCESS,
    UPDATE_LEAD_ERROR,
    DELETE_LEAD_REQUEST,
    DELETE_LEAD_SUCCESS,
    DELETE_LEAD_ERROR,
} from 'actions/homes.js';

export const initialState = {
    homes: [],
    isAddingOrUpdating: false,
    loading: false,
    error: null
};

export default function homesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_HOMES_REQUEST:
        case GET_VIRTUAL_HOME_REQUEST:
        case DELETE_HOME_REQUEST:
        case SEND_FOLLOW_UP_REQUEST:
        case UPDATE_LEAD_REQUEST:
        case DELETE_LEAD_REQUEST:
        case SEND_FOLLOW_UP_ALL_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ADD_HOME_REQUEST:
        case UPDATE_HOME_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                isAddingOrUpdating: true,
            };
        case GET_HOMES_ERROR:
        case GET_VIRTUAL_HOME_ERROR:
        case DELETE_HOME_ERROR:
        case SEND_FOLLOW_UP_ERROR:
        case UPDATE_LEAD_ERROR:
        case DELETE_LEAD_ERROR:
        case SEND_FOLLOW_UP_ALL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ADD_HOME_ERROR:
        case UPDATE_HOME_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                isAddingOrUpdating: false,
            };
        case DELETE_HOME_SUCCESS:
        case SEND_FOLLOW_UP_SUCCESS:
        case UPDATE_LEAD_SUCCESS:
        case DELETE_LEAD_SUCCESS:
        case SEND_FOLLOW_UP_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case ADD_HOME_SUCCESS:
        case UPDATE_HOME_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                isAddingOrUpdating: false,
            };
        case GET_HOMES_SUCCESS:
            return {
                ...state,
                homes: action.payload.data,
                loading: false,
                error: null,
            };
        case GET_VIRTUAL_HOME_SUCCESS:
            return {
                ...state,
                homes: [...state.homes, action.payload.data],
                loading: false,
                error: null,
            };
        default:
            return state;
    }
}
