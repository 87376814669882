import React, { useState, useEffect, useMemo } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {FaCog} from "react-icons/fa";
import Button from 'components/common/Button/Button.jsx';
import Search from 'components/common/Search/Search.jsx';
import HR from 'components/common/HR/HR.jsx';
import HomeCard from 'components/HomeCard/HomeCard.jsx';
import { generateLeadsCSV } from 'utils/generateCSV.js';
import { searchLeads } from "utils/leads.js";
import 'components/Leads/Leads.scss';

const Leads = ({
    homes,
    homeID,
    sendFollowUp,
    sendFollowUpAll,
    deleteLeadByHome,
    deleteHome,
}) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchDirection, setSearchDirection] = useState("Descending");
    const [queriedHomes, setQueriedHomes] = useState(homes);

    useEffect(() => {
        setQueriedHomes(searchLeads(homes, searchQuery, homeID));
    }, [searchQuery, homes, homeID]);

    useEffect(() => {
        if (searchDirection === "Descending") {
            setQueriedHomes(searchLeads(homes.slice().reverse(), searchQuery, homeID));
        } else {
            setQueriedHomes(searchLeads(homes, searchQuery, homeID));
        }
    }, [searchQuery, searchDirection, homes, homeID]);


    if (queriedHomes.length === 0 && homeID) return <Redirect to="/app/leads" />;
    return (
        <div className="Leads">
            <div className="LeadsTop">
                <div className="LeadsTopLeft">
                    <div className="LeadsTitle">{ homeID ? `Leads: ${queriedHomes[0] && queriedHomes[0].title}` : "All Leads" }</div>
                    <HR />
                </div>
                <Link to="/app/settings"><Button title="Settings" ButtonIcon={FaCog} outline/></Link>
            </div>
            { !homeID && homes.length > 0 && <Search searchQuery={searchQuery} searchDirection={searchDirection} setSearchQuery={setSearchQuery} setSearchDirection={setSearchDirection} /> }
            <div className="LeadsList">
                { homes.length > 0 ?
                    queriedHomes.length > 0 ?
                        queriedHomes.map(home =>
                            <HomeCard home={home} sendFollowUp={sendFollowUp} sendFollowUpAll={sendFollowUpAll} deleteLeadByHome={deleteLeadByHome} deleteHome={deleteHome} showLeads />
                        )
                        :
                        (
                            <div className="LeadsListEmpty">
                                <div className="LeadsListEmptyTitle">
                                    No Leads Found
                                </div>
                                <HR />
                                <div className="LeadsListEmptySubtitle">
                                    No Leads match your search. Try broadening your search.
                                </div>
                                <Button className="LeadsListEmptyButton" title="Clear Search" onClick={() => setSearchQuery("")}/>
                            </div>
                        )
                    : (
                        <div className="LeadsListEmpty">
                            <div className="LeadsListEmptyTitle">
                                No Leads Yet
                            </div>
                            <HR />
                            <div className="LeadsListEmptySubtitle">
                                You haven't collected any leads yet. To collect leads, start an Open House and allow your visitors to Sign In.
                            </div>
                            <Link to={"/app/homes/add"}><Button className="LeadsListEmptyButton" title="Add Home" outline /></Link>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

Leads.propTypes = {
    homes: PropTypes.any,
    sendFollowUp: PropTypes.func,
    sendFollowUpAll: PropTypes.func,
    deleteLeadByHome: PropTypes.func,
};

export default Leads;
