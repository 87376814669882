import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import "components/common/Button/Button.scss";

const Button = ({
    className,
    onClick,
    title,
    ButtonIcon,
    outline,
    size,
    color
}) => (
    <div className={cx(
        "Button",
        {
            "Button--large": size === 'large',
            "Button--small": size === 'small',
            "Button--fill": !outline,
            "Button--outline": outline,
            "Button--green": color === 'green',
            [className]: !!className,
        }
    )} onClick={onClick}>
        { title }
        { ButtonIcon && <ButtonIcon className="Button--icon" /> }
    </div>
);

Button.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.any,
    ButtonIcon: PropTypes.node,
    outline: PropTypes.bool,
    size: PropTypes.oneOf(["large", 'small']),
    color: PropTypes.string,
};

Button.defaultProps = {
    className: '',
    onClick: () => {},
    title: "",
    ButtonIcon: null,
    outline: false,
    size: "small",
    color: "blue",
};

export default Button;
