import React from 'react';
import 'components/SMS/SMS.scss';

const SMS = () => {
    return (
        <div className="SMS">
            <h1>SMS POLICY</h1>

            <h2>Effective date: 09/13/2020</h2>

            <div>You received a text message because you provided consent to be contacted by OPENHOMELY TECHNOLOGIES INC. by text message.</div>

            <div>You also acknowledged that message and data rates may have applied to your receipt of the message.</div>

            <div>To unsubscribe from further communications from OPENHOMELY TECHNOLOGIES INC. please reply "STOP" to the text message you received.</div>

            <div><b>OPENHOMELY TECHNOLOGIES INC. 1383 W. 8th Avenue, Vancouver BC, V6H 3W4 Canada</b></div>

            <div><b>support@openhomely.com</b></div>
        </div>
    );
};

export default SMS;
