import React from 'react';
import PropTypes from 'prop-types';
import { FaSearch, FaSortDown, FaSortUp } from 'react-icons/fa';
import 'components/common/Search/Search.scss';

const Search = ({
    setSearchQuery,
    searchQuery,
    setSearchDirection,
    searchDirection,
}) => {

    return (
        <div className="SearchWrapper">
            <div className="SearchLeft">
                <FaSearch className="SearchIcon" />
                <input
                    className="SearchInput"
                    placeholder="Search"
                    value={searchQuery}
                    onBlur={(e) => setSearchQuery(e.target.value)}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <div
                className="SearchRight"
                onClick={() => {
                    if (searchDirection === "Descending") {
                        setSearchDirection("Ascending")
                    } else {
                        setSearchDirection("Descending")
                    }
                }}
            >
                <div>Sorting {searchDirection}</div>
                {
                    searchDirection === "Descending" ?
                        <FaSortDown className="SearchSortIcon" style={{ transform: searchDirection === "Descending" ? "translateY(-25%)" : "translateY(25%)" }} />
                        :
                        <FaSortUp className="SearchSortIcon" style={{ transform: searchDirection === "Descending" ? "translateY(-25%)" : "translateY(25%)" }} />
                }
            </div>
        </div>
    )
};

Search.propTypes = {
    setSearchQuery: PropTypes.func.isRequired,
    searchQuery: PropTypes.string.isRequired,
    setSearchDirection: PropTypes.func.isRequired,
    searchDirection: PropTypes.string.isRequired,
};

export default Search;
