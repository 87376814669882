import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";

const ScrollToTop = withRouter(({ history, divId }) => {
    useEffect(() => {
        const unlisten = history.listen(() => {
            divId ? document.getElementById(divId).scrollTo(0,0) : window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    }, []);

    return (null);
})

export default ScrollToTop
