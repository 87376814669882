import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateProfile } from 'actions/auth.js';
import ProfileSettings from 'components/ProfileSettings/ProfileSettings.jsx';

const ProfileSettingsContainer = (props) => {
    const {
        userID,
        updateProfile,
        email,
        name,
        defaultWelcomeMessage,
        defaultFollowUpMessage,
        emailSignature,
        profileImage,
        updating,
    } = props;

    return <ProfileSettings
        email={email}
        name={name}
        defaultWelcomeMessage={defaultWelcomeMessage}
        defaultFollowUpMessage={defaultFollowUpMessage}
        emailSignature={emailSignature}
        profileImage={profileImage}
        updating={updating}
        updateProfile={
            ({ defaultWelcomeMessage, name, defaultFollowUpMessage, emailSignature, profileImage }) =>
                updateProfile({ userID, name, defaultWelcomeMessage, defaultFollowUpMessage, emailSignature, profileImage }
            )}
    />
};

const mapStateToProps = (state) => {
    return {
        userID: state.auth.userID,
        email: state.auth.email,
        name: state.auth.name,
        defaultWelcomeMessage: state.auth.messages.defaultWelcomeMessage,
        defaultFollowUpMessage: state.auth.messages.defaultFollowUpMessage,
        emailSignature: state.auth.emailSignature,
        profileImage: state.auth.profileImage,
        error: state.auth.error,
        authenticated: state.auth.authenticated,
        updating: state.auth.updating,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateProfile,
    }, dispatch);
};

ProfileSettingsContainer.propTypes = {
    userID: PropTypes.string,
};

ProfileSettingsContainer.defautProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettingsContainer);
