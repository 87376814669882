import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { searchHomes } from 'utils/homes';
import Button from 'components/common/Button/Button.jsx';
import Modal from 'components/common/Modal/Modal.jsx';
import Dialog from 'components/common/Dialog/Dialog.jsx';
import Search from 'components/common/Search/Search.jsx';
import HR from 'components/common/HR/HR.jsx';
import HomeCard from 'components/HomeCard/HomeCard.jsx';
import { FaEdit, FaTrashAlt, FaCog } from 'react-icons/fa';
import 'components/Homes/Homes.scss';

const Homes = ({ homes, deleteHome, sendFollowUpAll }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchDirection, setSearchDirection] = useState("Descending");
    const [queriedHomes, setQueriedHomes] = useState(homes.slice().reverse());

    useEffect(() => {
        if (searchDirection === "Descending") {
            setQueriedHomes(searchHomes(homes.slice().reverse(), searchQuery));
        } else {
            setQueriedHomes(searchHomes(homes, searchQuery));
        }
    }, [searchQuery, searchDirection, homes]);

    return (
        <div className="HomesList">
            <div className="HomesTop">
                <div className="HomesTopLeft">
                    <div className="HomesTitle">Homes</div>
                    <HR />
                </div>
                <Link to="/app/settings"><Button title="Settings" ButtonIcon={FaCog} outline/></Link>
            </div>
            { homes.length > 0 && <Search searchQuery={searchQuery} searchDirection={searchDirection} setSearchQuery={setSearchQuery} setSearchDirection={setSearchDirection} /> }
            <div className="HomesList">
                {
                    homes.length > 0 ?
                        queriedHomes.length > 0 ?
                            queriedHomes.map((home) => (
                                <HomeCard home={home} key={home.homeID} deleteHome={deleteHome} sendFollowUpAll={sendFollowUpAll} showOverview/>
                            )) :
                            (
                                <div className="HomesListEmpty">
                                    <div className="HomesListEmptyTitle">
                                        No Homes Found
                                    </div>
                                    <HR />
                                    <div className="HomesListEmptySubtitle">
                                        No Homes match your search. Try broadening your search.
                                    </div>
                                    <Button className="HomesListEmptyButton" title="Clear Search" onClick={() => setSearchQuery("")}/>
                                </div>
                            )
                        : (
                            <div className="HomesListEmpty">
                                <div className="HomesListEmptyTitle">
                                    No Homes Yet
                                </div>
                                <HR />
                                <div className="HomesListEmptySubtitle">
                                    You haven't added any homes yet.
                                </div>
                                <Link to={"/app/homes/add"}><Button className="HomesListEmptyButton" title="Add Home" outline /></Link>
                            </div>
                        )
                }
            </div>
        </div>
    )
};

Homes.propTypes = {
    deleteHome: PropTypes.func.isRequired,
    homes: PropTypes.arrayOf({
        homeID: PropTypes.string,
        title: PropTypes.string,
        link: PropTypes.string,
        welcomeMessage: PropTypes.string,
        followUpMessage: PropTypes.string,
    }),
};

Homes.defaultProps = {
    homes: [],
};

export default Homes;
