import React from 'react';
import PropTypes from 'prop-types';
import {
    Switch,
    Redirect,
    Route,
    useParams,
} from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dashboard from 'containers/DashboardContainer.jsx';
import OpenHouse from 'containers/OpenHouseContainer.jsx';
import Leads from 'containers/LeadsContainer.jsx';
import Lead from 'containers/LeadContainer.jsx';
import Settings from 'containers/SettingsContainer.jsx';
import Homes from 'containers/HomesContainer.jsx';
import AddEditHome from 'containers/AddEditHomeContainer.jsx';
import App from 'components/App/App.jsx';

const AppRoutesContainer = () => (
    <Switch>
        <Route path="/app/dashboard/:homeID/openhouse" component={OpenHouse} exact />
        <App>
            <Switch>
                <Route path="/app/dashboard" component={Dashboard} exact />
                <Route path="/app/settings" component={Settings} exact />
                <Route path="/app/homes" component={Homes} exact />
                <Route path="/app/homes/add" component={AddEditHome} exact />
                <Route path="/app/homes/edit/:homeID" component={AddEditHome} exact />
                <Route path="/app/homes/:homeID/leads/:leadID" component={Lead} exact />
                <Route path="/app/leads" component={Leads} exact />
                <Redirect to="/" />
            </Switch>
        </App>
    </Switch>
);

const mapStateToProps = (state) => {
    return {
        name: state.auth.name,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};

AppRoutesContainer.propTypes = {
    name: PropTypes.string,
};

AppRoutesContainer.defautProps = {
    name: ""
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutesContainer);
