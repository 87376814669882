import axios from 'axios';
import {
    LOGOUT,
    LOGIN_REQUEST,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    SIGNUP_REQUEST,
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    TOKEN_LOGIN_REQUEST,
    TOKEN_LOGIN_ERROR,
    TOKEN_LOGIN_SUCCESS,
    REQUEST_RESET_PASSWORD_REQUEST,
    REQUEST_RESET_PASSWORD_SUCCESS,
    REQUEST_RESET_PASSWORD_ERROR,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    DELETE_ACCOUNT_REQUEST,
    DELETE_ACCOUNT_ERROR,
    DELETE_ACCOUNT_SUCCESS,
    GET_ACCOUNT_REQUEST,
    GET_ACCOUNT_ERROR,
    GET_ACCOUNT_SUCCESS,
    WITHDRAW_CONSENT_REQUEST,
    WITHDRAW_CONSENT_ERROR,
    WITHDRAW_CONSENT_SUCCESS,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_ERROR,
    UPDATE_PROFILE_SUCCESS,
} from 'actions/auth.js';

export const initialState = {
    jwt: localStorage.getItem('jwt') || '',
    name: '',
    email: '',
    userID: '',
    subscribed: false,
    unsubscribedReason: '',
    payment: {
        success: false,
        stripe_session: '',
        stripe_payment_intent_client_secret: '',
        stripe_payment_method: '',
    },
    messages: {
        defaultWelcomeMessage: '',
        defaultFollowUpMessage: '',
    },
    emailSignature: '',
    profileImage: null,
    usage: 0,
    updating: false,
    loading: false,
    error: null,
    authenticated: false,
};

export default function authReducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOGOUT:
        case RESET_PASSWORD_SUCCESS:
        case DELETE_ACCOUNT_SUCCESS:
            localStorage.removeItem('jwt');
            window.location.href = '/';
            return initialState;
        case LOGIN_REQUEST:
        case SIGNUP_REQUEST:
        case TOKEN_LOGIN_REQUEST:
        case REQUEST_RESET_PASSWORD_REQUEST:
        case RESET_PASSWORD_REQUEST:
        case DELETE_ACCOUNT_REQUEST:
        case WITHDRAW_CONSENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                updating: false,
            };
        case UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                updating: true,
            };
        case LOGIN_ERROR:
        case SIGNUP_ERROR:
        case TOKEN_LOGIN_ERROR:
        case REQUEST_RESET_PASSWORD_ERROR:
        case RESET_PASSWORD_ERROR:
        case DELETE_ACCOUNT_ERROR:
        case GET_ACCOUNT_ERROR:
        case WITHDRAW_CONSENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case UPDATE_PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                updating: false,
            };
        case LOGIN_SUCCESS:
        case SIGNUP_SUCCESS:
        case TOKEN_LOGIN_SUCCESS:
            localStorage.setItem('jwt', action.payload.data.jwt);
            axios.defaults.headers.common.jwt = action.payload.data.jwt;
            return {
                ...state,
                loading: false,
                error: null,
                updating: false,
                name: action.payload.data.name,
                email: action.payload.data.email,
                userID: action.payload.data.userID,
                subscribed: action.payload.data.subscribed,
                unsubscribedReason: action.payload.data.unsubscribedReason,
                payment: {
                    success: action.payload.data.payment.success,
                    stripe_session: action.payload.data.payment.stripe_session,
                    stripe_payment_intent_client_secret: action.payload.data.payment.stripe_payment_intent_client_secret,
                    stripe_payment_method: action.payload.data.payment.stripe_payment_method,
                },
                messages: {
                    defaultWelcomeMessage: action.payload.data.messages.default_welcome_message,
                    defaultFollowUpMessage: action.payload.data.messages.default_follow_up_message,
                },
                emailSignature: action.payload.data.emailSignature,
                profileImage: action.payload.data.profileImage,
                usage: action.payload.data.usage,
                jwt: action.payload.data.jwt,
                authenticated: true,
            };
        case GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                updating: false,
                name: action.payload.data.name,
                email: action.payload.data.email,
                userID: action.payload.data.userID,
                subscribed: action.payload.data.subscribed,
                unsubscribedReason: action.payload.data.unsubscribedReason,
                payment: {
                    success: action.payload.data.payment.success,
                    stripe_session: action.payload.data.payment.stripe_session,
                    stripe_payment_intent_client_secret: action.payload.data.payment.stripe_payment_intent_client_secret,
                    stripe_payment_method: action.payload.data.payment.stripe_payment_method,
                },
                emailSignature: action.payload.data.emailSignature,
                profileImage: action.payload.data.profileImage,
                messages: {
                    defaultWelcomeMessage: action.payload.data.messages.default_welcome_message,
                    defaultFollowUpMessage: action.payload.data.messages.default_follow_up_message,
                },
                usage: action.payload.data.usage,
            };
        case REQUEST_RESET_PASSWORD_SUCCESS:
        case RESET_PASSWORD_SUCCESS:
        case DELETE_ACCOUNT_SUCCESS:
        case WITHDRAW_CONSENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                updating: false,
            };
        default:
            return state;
    }
}
