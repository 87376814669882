import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTransition, animated, config } from 'react-spring';
import { FaCheck, FaArrowLeft, FaExclamationTriangle } from 'react-icons/fa';
import { withFormik } from 'formik';
import cx from 'classnames';
import Button from 'components/common/Button/Button.jsx';
import HR from 'components/common/HR/HR.jsx';
import 'components/OpenHouse/OpenHouse.scss';

const OpenHouse = ({
   house,
   handleSubmit,
   handleChange,
   handleBlur,
   values,
   touched,
   errors,
   initialErrors,
   initialStatus,
   isUpdate,
   isValid,
   isVirtual,
   loadingHomes,
}) => {
    const [allowShowError, setAllowShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        if (loadingHomes) setTimeout(() => setAllowShowError(true), 2000);
    }, [loadingHomes]);

    const toggleSuccess = () => {
        if (isValid && Object.keys(touched).length > 0) {
            setShowSuccess(true);
            if (!isVirtual) {
                setTimeout(() => {
                    setShowSuccess(false);
                }, 5000)
            }
        }
    };

    const transitions = useTransition(showSuccess, null, {
        from: {
            position: 'absolute',
            transform: 'translate(0%, 5%)',
            opacity: 0,
        },
        enter: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        leave: {
            transform: 'translate(0%, 5%)',
            opacity: 0,
        },
        config: config.default,
    });

    return (
        <div className="OpenHomePage">
            <div className="OpenHomePageWrapper">
                <div className="OpenHomePageInner">
                    { !isVirtual && <Link to="/app/dashboard" className="OpenHouseQuit"><FaArrowLeft className="OpenHouseQuitIcon" />Quit Open House</Link> }
                    <div className="OpenHomeBGBar"/>
                    {!house && !loadingHomes && allowShowError &&
                        <div className="OpenHomeError">
                            <FaExclamationTriangle className="OpenHomeErrorIcon"/>
                            <div className="OpenHomeErrorText">Sorry, this Open House is no longer available.</div>
                            <div className="OpenHomeErrorTextSecondary">Please contact the individual responsible for this Open House if this is in error.</div>
                            <Button className="OpenHomeErrorButton" onClick={() => window.history.back()} title="Go Back" />
                        </div>
                    }
                    {house && transitions.map(({ item, key, props }) => {
                        return !item ? (
                            <animated.div className={cx("OpenHomeContainer", { "OpenHomeContainer--centered" : !(Boolean(house.image))})} style={props} key={key}>
                                <div className="OpenHomeInner">
                                    <div className="OpenHomeRow">
                                        <div className={cx("OpenHome", { "OpenHome--fullwidth": !(Boolean(house.image)) })}>
                                            <div className="OpenHomeTitle">Welcome,</div>
                                            <div className="OpenHomeSubtitleRow">
                                                <div className="OpenHomeSubtitle">Please Sign In</div>
                                                {/*<div className="OpenHomeAddress">{ house && house.title }</div>*/}
                                            </div>
                                            <HR className="OpenHomeHR"/>
                                            <form className="OpenHomeForm" onSubmit={handleSubmit}>
                                                <div className="OpenHomeFormInputTitle">Name</div>
                                                <div className="OpenHomeFormInputWrapper">
                                                    <input
                                                        className="OpenHomeFormInput"
                                                        type="text"
                                                        placeholder="Your name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.name}
                                                        name="name"
                                                    />
                                                </div>
                                                {errors.name && touched.name && <div className="OpenHomeFormInputFeedback">{errors.name}</div>}
                                                <div className="OpenHomeFormInputTitle">Email</div>
                                                <div className="OpenHomeFormInputWrapper">
                                                    <input
                                                        className="OpenHomeFormInput"
                                                        type="email"
                                                        placeholder="Your email"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.email}
                                                        name="email"
                                                    />
                                                </div>
                                                {errors.email && touched.email && <div className="OpenHomeFormInputFeedback">{errors.email}</div>}
                                                <div className="OpenHomeFormInputTitle">Phone Number</div>
                                                <div className="OpenHomeFormInputWrapper">
                                                    <div className="OpenHomeFormInputTag">+1</div>
                                                    <input
                                                        className="OpenHomeFormInput"
                                                        type="tel"
                                                        placeholder="Your phone number"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.phone}
                                                        name="phone"
                                                    />
                                                </div>
                                                {errors.phone && touched.phone && <div className="OpenHomeFormInputFeedback">{errors.phone}</div>}
                                                <div className="OpenHomeCheckboxWrapper">
                                                    <input
                                                        className="OpenHomeFormCheckboxInput"
                                                        type="checkbox"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.tocppagreement}
                                                        name="tocppagreement"
                                                    />
                                                    <div className="OpenHomeCheckboxDescription">I agree to the <Link className="OpenHomeAgreementLink" to="/terms">Terms and Conditions</Link> and <Link className="OpenHomeAgreementLink" to="/privacy">Privacy Policy</Link>.</div>
                                                </div>
                                                {errors.tocppagreement && touched.tocppagreement && <div className="OpenHomeFormInputFeedback">{errors.tocppagreement}</div>}
                                                <div className="OpenHomeCheckboxWrapper">
                                                    <input
                                                        className="OpenHomeFormCheckboxInput"
                                                        type="checkbox"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.contactagreement}
                                                        name="contactagreement"
                                                    />
                                                    <div className="OpenHomeCheckboxDescription">I agree to receive communication by Email and Text Message. Messages and data rates may apply.</div>
                                                </div>
                                                {errors.contactagreement && touched.contactagreement && <div className="OpenHomeFormInputFeedback">{errors.contactagreement}</div>}
                                                <button className="OpenHomeFormButton" type="submit" onClick={toggleSuccess}><Button title="Sign In" /></button>
                                            </form>
                                        </div>
                                        {
                                            house.image && (
                                                <div className="OpenHomeImageWrapper">
                                                    <div className="OpenHomeImageTitle">{house.title}</div>
                                                    <HR className="OpenHomeImageTitleHR"/>
                                                    <div className="OpenHomeImageAddress">{house.address}</div>
                                                    <img className="OpenHomeImage" src={house.image}/>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </animated.div>
                        ) : (
                            <animated.div style={props} key={key}>
                                <div className="OpenHomeSuccess">
                                    <FaCheck className="OpenHomeSuccessIcon" />
                                    {
                                        isVirtual ?
                                            <div className="OpenHomeSuccessText">Signed in Successfully. You will be redirected momentarily.</div>
                                            :
                                            <div className="OpenHomeSuccessText">Signed in Successfully.</div>
                                    }
                                </div>
                            </animated.div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
};

const OpenHouseForm = withFormik({

    validateOnMount: true,

    mapPropsToValues: () => {
        return {
            name: '', email: '', phone: '', tocppagreement: false, contactagreement: false
        }
    },

    validate: values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'A name is required.';
        }

        if (!values.email) {
            errors.email = 'An email is required.';
        }

        if (!values.phone || !(/^\d{3}[- ]?\d{3}[- ]?\d{4}$/.test(values.phone))) {
            errors.phone = 'A valid phone number is required.';
        }

        if (!values.tocppagreement) {
            errors.tocppagreement = 'You must agree to continue.'
        }

        if (!values.contactagreement) {
            errors.contactagreement = 'You must agree to continue.'
        }

        return errors;
    },

    handleSubmit: (values, { setSubmitting, props, resetForm }) => {
        if (props.isVirtual) {
            props.captureVirtualLeadByHome(values.name, values.email, "+1" + values.phone.replace(/[ -]/g, ""));
            setTimeout(() => {
                window.location.replace(props.house.link);
            }, 3000);
        } else {
            props.captureLeadByHome(values.name, values.email, "+1" + values.phone.replace(/[ -]/g, ""));
        }
        resetForm();
        setSubmitting(false);
    },

    displayName: 'OpenHouseForm',
})(OpenHouse);

OpenHouse.propTypes = {
    house: PropTypes.any,
    captureLeadByHome: PropTypes.func.isRequired,
    isVirtual: PropTypes.bool,
};

export default OpenHouseForm;
