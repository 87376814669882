import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSpring, animated, config } from 'react-spring';
import { withFormik } from 'formik';
import logo from 'assets/OpenHomely_Logo_White.svg';
import AuthBg from 'components/AuthBg/AuthBg.jsx';
import Button from 'components/common/Button/Button.jsx';
import 'components/Signup/Signup.scss';

const Signup = ({
   handleSubmit,
   handleChange,
   handleBlur,
   values,
   touched,
   errors,
   error,
}) => {

    const [submitted, setSubmitted] = useState(false);

    return (
        <>
            <AuthBg />
            <div className="Signup">
                <div className="SignupWrapper">
                    <div className="SignupInner">
                        <Link to="/"><img className="SignupLogo" src={logo} alt="OpenHomely"/></Link>
                        <div className="SignupFormContainer">
                            <div className="SignupFormTitle">Sign Up</div>
                            <form className="SignupForm" onSubmit={handleSubmit}>
                                <div className="SignupFormInputTitle">Name</div>
                                <input
                                    className="SignupFormInput"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    name="name"
                                />
                                {errors.name && touched.name && <div className="SignupFormInputFeedback">{errors.name}</div>}
                                <div className="SignupFormInputTitle">Email</div>
                                <input
                                    className="SignupFormInput"
                                    type="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    name="email"
                                />
                                {errors.email && touched.email && <div className="SignupFormInputFeedback">{errors.email}</div>}
                                <div className="SignupFormInputTitle">Password</div>
                                <input
                                    className="SignupFormInput"
                                    type="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    name="password"
                                />
                                {errors.password && touched.password &&
                                <div className="SignupFormInputFeedback">{errors.password}</div>}
                                <div className="SignupFormInputTitle">Confirm Password</div>
                                <input
                                    className="SignupFormInput"
                                    type="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.confirmPassword}
                                    name="confirmPassword"
                                />
                                {errors.confirmPassword && touched.confirmPassword &&
                                <div className="SignupFormInputFeedback">{errors.confirmPassword}</div>}
                                <div className="SignupFormAgreement">
                                    <input
                                        className="SignupFormInput--Agreement"
                                        type="checkbox"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.agreement}
                                        name="agreement"
                                    />
                                    <div className="SignupFormInputTitle--Agreement">I agree to both the <Link className="SignupFormLink" to="/terms">Terms and Conditions</Link> and <Link className="SignupFormLink" to="/privacy">Privacy Policy</Link>.</div>
                                </div>
                                {errors.agreement && touched.agreement &&
                                <div className="SignupFormInputFeedback">{errors.agreement}</div>}
                                {submitted && error && error.response && <div className="SignupFormInputFeedback">{error.response.data}</div>}
                                <button className="SignupFormButton" type="submit" onClick={() => setSubmitted(true)}><Button title="Sign Up"/></button>
                                <div className="SignupFormLoginInstead"><Link to="/login">Already have an account? Login instead.</Link></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const SignupForm = withFormik({
    mapPropsToValues: () => ({ name: '', email: '', password: '', confirmPassword: '', agreement: '' }),

    validate: values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Your name is required.';
        }

        if (!values.email) {
            errors.email = 'Your email is required.';
        }

        if (!values.password) {
            errors.password = 'A password is required.';
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = 'You must confirm your password.';
        }

        if (values.confirmPassword && values.password && values.confirmPassword !== values.password) {
            errors.confirmPassword = 'Your passwords must match.';
        }

        if (!values.agreement) {
            errors.agreement = 'You must agree to the necessary terms.';
        }

        return errors;
    },

    handleSubmit: (values, { setSubmitting, props }) => {
        props.signup(values.name, values.email, values.password);
        setSubmitting(false);
    },

    displayName: 'SignupForm',
})(Signup);

Signup.propTypes = {
    name: PropTypes.string,
    errors: PropTypes.any,
    handleSubmit: PropTypes.func.isRequired,
    signup: PropTypes.func.isRequired,
};

Signup.defaultProps = {
    name: '',
};

export default SignupForm;
