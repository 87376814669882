import * as AuthService from 'services/auth.js';

export const TOKEN_LOGIN_REQUEST = 'TOKEN_LOGIN_REQUEST';
export const TOKEN_LOGIN_ERROR = 'TOKEN_LOGIN_ERROR';
export const TOKEN_LOGIN_SUCCESS = 'TOKEN_LOGIN_SUCCESS';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';

export const REQUEST_RESET_PASSWORD_REQUEST = 'REQUEST_RESET_PASSWORD_REQUEST';
export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';
export const REQUEST_RESET_PASSWORD_ERROR = 'REQUEST_RESET_PASSWORD_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const UPDATE_MESSAGES_REQUEST = 'UPDATE_MESSAGES_REQUEST';
export const UPDATE_MESSAGES_SUCCESS = 'UPDATE_MESSAGES_SUCCESS';
export const UPDATE_MESSAGES_ERROR = 'UPDATE_MESSAGES_ERROR';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export const WITHDRAW_CONSENT_REQUEST = 'WITHDRAW_CONSENT_REQUEST';
export const WITHDRAW_CONSENT_SUCCESS = 'WITHDRAW_CONSENT_SUCCESS';
export const WITHDRAW_CONSENT_ERROR = 'WITHDRAW_CONSENT_ERROR';

export const LOGOUT = 'LOGOUT';

export const logout = () => ({
    type: LOGOUT,
});

export const login = (email, password) => {
    const request = AuthService.login(email, password);

    return (dispatch) => {
        dispatch({
            type: LOGIN_REQUEST
        });

        request.then(
            payload => dispatch({
                type: LOGIN_SUCCESS,
                payload,
            })
        ).catch(
            error => dispatch({
                type: LOGIN_ERROR,
                error,
            })
        );
    };
};


export const tokenLogin = (jwt) => {
    const request = AuthService.tokenLogin(jwt);

    return (dispatch) => {
        dispatch({
            type: TOKEN_LOGIN_REQUEST
        });

        request.then(
            payload => dispatch({
                type: TOKEN_LOGIN_SUCCESS,
                payload,
            })
        ).catch(
            error => dispatch({
                type: TOKEN_LOGIN_ERROR,
                error,
            })
        );
    };
};

export const signup = (name, email, password) => {
    const request = AuthService.signup(name, email, password);

    return (dispatch) => {
        dispatch({
            type: SIGNUP_REQUEST
        });

        request.then(
            payload => dispatch({
                type: SIGNUP_SUCCESS,
                payload,
            })
        ).catch(
            error => dispatch({
                type: SIGNUP_ERROR,
                error,
            })
        );
    };
};

export const resetPasswordRequest = (email) => {
    const request = AuthService.resetPasswordRequest(email);

    return (dispatch) => {
        dispatch({
            type: REQUEST_RESET_PASSWORD_REQUEST
        });

        request.then(
            payload => dispatch({
                type: REQUEST_RESET_PASSWORD_SUCCESS,
                payload,
            })
        ).catch(
            error => dispatch({
                type: REQUEST_RESET_PASSWORD_ERROR,
                error,
            })
        );
    };
};

export const resetPassword = (email, password, hash) => {
    const request = AuthService.resetPassword(email, password, hash);

    return (dispatch) => {
        dispatch({
            type: RESET_PASSWORD_REQUEST
        });

        request.then(
            payload => dispatch({
                type: RESET_PASSWORD_SUCCESS,
                payload,
            })
        ).catch(
            error => dispatch({
                type: RESET_PASSWORD_ERROR,
                error,
            })
        );
    };
};

export const deleteAccount = () => {
    const request = AuthService.deleteAccount();

    return (dispatch) => {
        dispatch({
            type: DELETE_ACCOUNT_REQUEST
        });

        request.then(
            payload => dispatch({
                type: DELETE_ACCOUNT_SUCCESS,
                payload,
            })
        ).catch(
            error => dispatch({
                type: DELETE_ACCOUNT_ERROR,
                error,
            })
        );
    };
};

export const updateMessages = (userID, defaultWelcomeMessage, defaultFollowUpMessage) => {
    const request = AuthService.updateMessages(userID, defaultWelcomeMessage, defaultFollowUpMessage);

    return (dispatch) => {
        dispatch({
            type: UPDATE_MESSAGES_REQUEST
        });

        request.then(
            payload => {
                dispatch(getAccount(userID));
                dispatch({
                    type: UPDATE_MESSAGES_SUCCESS,
                    payload,
                });
            }
        ).catch(
            error => dispatch({
                type: UPDATE_MESSAGES_ERROR,
                error,
            })
        );
    };
}

export const updateProfile = ({ userID, name,  defaultWelcomeMessage, defaultFollowUpMessage, profileImage, emailSignature }) => {
    const request = AuthService.updateProfile({ userID, name, defaultWelcomeMessage, defaultFollowUpMessage, profileImage, emailSignature });

    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_REQUEST
        });

        request.then(
            payload => {
                dispatch(getAccount(userID));
                dispatch({
                    type: UPDATE_PROFILE_SUCCESS,
                    payload,
                });
            }
        ).catch(
            error => dispatch({
                type: UPDATE_PROFILE_ERROR,
                error,
            })
        );
    };
}

export const getAccount = (userID) => {
    const request = AuthService.getAccount(userID);

    return (dispatch) => {
        dispatch({
            type: GET_ACCOUNT_REQUEST
        });

        request.then(
            payload => dispatch({
                type: GET_ACCOUNT_SUCCESS,
                payload,
            })
        ).catch(
            error => dispatch({
                type: GET_ACCOUNT_ERROR,
                error,
            })
        );
    };
};

export const withdrawConsent = (email) => {
    const request = AuthService.withdrawConsent(email);

    return (dispatch) => {
        dispatch({
            type: WITHDRAW_CONSENT_REQUEST
        });

        request.then(
            payload => dispatch({
                type: WITHDRAW_CONSENT_SUCCESS,
                payload,
            })
        ).catch(
            error => dispatch({
                type: WITHDRAW_CONSENT_ERROR,
                error,
            })
        );
    };
};

