import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import {FaFileUpload} from "react-icons/fa";
import Button from 'components/common/Button/Button.jsx';
import Monaco from "components/common/Monaco/Monaco.jsx";
import Spinner from "components/common/Spinner/Spinner.jsx";
import 'components/ProfileSettings/ProfileSettings.scss';
import {config, useTransition, animated} from "react-spring";

const ProfileSettings = ({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    initialValues,
    touched,
    errors,
    isUpdate,
    setFieldValue,
    updating,
}) => {
    const profileImageInputRef = useRef(null);
    const [validatingProfileImage, setValidatingProfileImage] = useState(false);
    const [profileImageError, setProfileImageError] = useState('');

    const [showIsUpdating, setShowIsUpdating] = useState(false);
    const [showIsUpdatingCallback, setShowIsUpdatingCallback] = useState(false);

    const [isTypingCallback, setIsTypingCallback] = useState(null);

    const handleChangeTimeout = (e) => {
        if (isTypingCallback) {
            clearTimeout(isTypingCallback);
        }

        setIsTypingCallback(setTimeout(() => {
            handleSubmit(e)
        }, 1000));

        handleChange(e);
    };

    useEffect(() => {
        if (updating) {
            setShowIsUpdating(true);
            if (showIsUpdatingCallback) {
                clearTimeout(showIsUpdatingCallback);
            }
            setShowIsUpdatingCallback(setTimeout(() => {
                setShowIsUpdating(false);
            }, 1000));
        }
    }, [updating]);

    const showIsUpdatingTransitions = useTransition(showIsUpdating, null, {
        from: {
            position: 'absolute',
            transform: 'translate(0%, 5%)',
            opacity: 0,
            display: 'block',
        },
        enter: {
            transform: 'translate(0%, 0%)',
            opacity: 1,
        },
        leave: {
            transform: 'translate(0%, 5%)',
            opacity: 0,
            display: 'none',
        },
        config: config.default,
    });

    return (
        <div className="ProfileSettingsWrapper" >
            <form className="ProfileSettingsForm" onSubmit={handleSubmit}>
                <div className="ProfileSettingsFormTitle">Your Email</div>
                <div
                    className="ProfileSettingsFormInput ProfileSettingsFormInput--disabled"
                >
                    {values.email}
                </div>

                <div className="ProfileSettingsFormTitle">Your Name</div>
                <input
                    className="ProfileSettingsFormInput"
                    type="text"
                    placeholder="Your Name"
                    onChange={handleChangeTimeout}
                    onBlur={handleSubmit}
                    value={values.name}
                    name="name"
                />
                <div className="ProfileSettingsFormUpdating" >
                    <div style={{ opacity: 0 }}>Saving...</div>
                    {showIsUpdatingTransitions.map(({ item, key, props }) => {
                        return !item ?
                            <animated.div style={props} key={key} className="ProfileSettingsFormUpdatingLabel"></animated.div>
                            :
                            <animated.div style={props} key={key} className="ProfileSettingsFormUpdatingLabel">Saving...</animated.div>
                    })}
                </div>
                {errors.name && touched.name && <div className="ProfileSettingsFormInputFeedback">{errors.name}</div>}

                <div className="ProfileSettingsFormTitle">Email Profile Image</div>
                <label for="settings-profile-image-input">
                    <Button className="ProfileSettingsFormImageButton" title="Upload Profile Image" ButtonIcon={FaFileUpload} outline />
                </label>
                <input
                    id="settings-profile-image-input"
                    className="ProfileSettingsFormImageInput"
                    type="file"
                    accept="image/png, image/jpeg"
                    ref={profileImageInputRef}
                    onChange={e => {
                        setValidatingProfileImage(true);
                        setProfileImageError('');

                        const file = e.currentTarget.files[0];

                        let img = new Image();
                        img.src = window.URL.createObjectURL(file);
                        img.onload = () => {
                            if (img.width !== img.height) {
                                setProfileImageError('Your profile image must have Aspect Ratio 1:1 (Square)');
                                setFieldValue('profileImage', initialValues.profileImage);
                            } else {
                                setFieldValue('profileImage', file);
                                handleSubmit(e)
                            }
                            setValidatingProfileImage(false);
                        }
                    }}
                    name="image"
                />
                {
                    validatingProfileImage ?
                        <Spinner />
                        :
                        <>
                            <img src={typeof values.profileImage === "object" && values.profileImage !== null ? window.URL.createObjectURL(values.profileImage) : values.profileImage} className="ProfileSettingsFormImage" />
                            <div className="ProfileSettingsFormInputFeedback">{profileImageError}</div>
                        </>
                }

                <div className="ProfileSettingsFormTitle">Email Signature</div>
                <Monaco
                    className="ProfileSettingsFormTextArea"
                    id="emailSignature"
                    name="emailSignature"
                    defaultValue={values.emailSignature}
                    onChange={handleChangeTimeout}
                    onBlur={handleSubmit}
                />
                <div className="ProfileSettingsFormUpdating" >
                    <div style={{ opacity: 0 }}>Saving...</div>
                    {showIsUpdatingTransitions.map(({ item, key, props }) => {
                        return !item ?
                            <animated.div style={props} key={key} className="ProfileSettingsFormUpdatingLabel"></animated.div>
                            :
                            <animated.div style={props} key={key} className="ProfileSettingsFormUpdatingLabel">Saving...</animated.div>
                    })}
                </div>
                {errors.emailSignature && touched.emailSignature && <div className="ProfileSettingsFormInputFeedback">{errors.emailSignature}</div>}

                <div className="ProfileSettingsFormTitle">Default Welcome Message</div>
                <Monaco
                    className="ProfileSettingsFormTextArea"
                    id="defaultWelcomeMessage"
                    name="defaultWelcomeMessage"
                    defaultValue={values.defaultWelcomeMessage}
                    onChange={handleChangeTimeout}
                    onBlur={handleSubmit}
                />
                <div className="ProfileSettingsFormUpdating" >
                    <div style={{ opacity: 0 }}>Saving...</div>
                    {showIsUpdatingTransitions.map(({ item, key, props }) => {
                        return !item ?
                            <animated.div style={props} key={key} className="ProfileSettingsFormUpdatingLabel"></animated.div>
                            :
                            <animated.div style={props} key={key} className="ProfileSettingsFormUpdatingLabel">Saving...</animated.div>
                    })}
                </div>
                <div class="ProfileSettingsMonacoDescription">
                    <div className="ProfileSettingsMonacoDescriptionItem"><span className="ProfileSettingsMonacoDescription--keyword">[name]</span> becomes the name of the lead who signed into the open house.</div>
                    <div className="ProfileSettingsMonacoDescriptionItem"><span className="ProfileSettingsMonacoDescription--keyword">[title]</span> becomes the title of the home.</div>
                    <div className="ProfileSettingsMonacoDescriptionItem"><span className="ProfileSettingsMonacoDescription--keyword">[address]</span> becomes the address of the home.</div>
                    <div className="ProfileSettingsMonacoDescriptionItem"><span className="ProfileSettingsMonacoDescription--keyword">[link]</span> becomes the link for the home.</div>
                </div>
                {errors.defaultWelcomeMessage && touched.defaultWelcomeMessage && <div className="ProfileSettingsFormInputFeedback">{errors.defaultWelcomeMessage}</div>}

                <div className="ProfileSettingsFormTitle">Default Follow-Up Message</div>
                <Monaco
                    className="ProfileSettingsFormTextArea"
                    id="defaultFollowUpMessage"
                    name="defaultFollowUpMessage"
                    defaultValue={values.defaultFollowUpMessage}
                    onChange={handleChangeTimeout}
                    onBlur={handleSubmit}
                />
                <div className="ProfileSettingsFormUpdating" >
                    <div style={{ opacity: 0 }}>Saving...</div>
                    {showIsUpdatingTransitions.map(({ item, key, props }) => {
                        return !item ?
                            <animated.div style={props} key={key} className="ProfileSettingsFormUpdatingLabel"></animated.div>
                            :
                            <animated.div style={props} key={key} className="ProfileSettingsFormUpdatingLabel">Saving...</animated.div>
                    })}
                </div>
                <div class="ProfileSettingsMonacoDescription">
                    <div className="ProfileSettingsMonacoDescriptionItem"><span className="ProfileSettingsMonacoDescription--keyword">[name]</span> becomes the name of the lead who signed into the open house.</div>
                    <div className="ProfileSettingsMonacoDescriptionItem"><span className="ProfileSettingsMonacoDescription--keyword">[title]</span> becomes the title of the home.</div>
                    <div className="ProfileSettingsMonacoDescriptionItem"><span className="ProfileSettingsMonacoDescription--keyword">[address]</span> becomes the address of the home.</div>
                    <div className="ProfileSettingsMonacoDescriptionItem"><span className="ProfileSettingsMonacoDescription--keyword">[link]</span> becomes the link for the home.</div>
                </div>

                {errors.defaultFollowUpMessage && touched.defaultFollowUpMessage && <div className="ProfileSettingsFormInputFeedback">{errors.defaultFollowUpMessage}</div>}
                {/*<button className="ProfileSettingsFormButton" type="submit"><Button title="Save Profile" /></button>*/}
            </form>
        </div>
    )
};

const ProfileSettingsForm = withFormik({
    mapPropsToValues: (props) => {
        return {
            email: props.email,
            name: props.name,
            defaultWelcomeMessage: props.defaultWelcomeMessage,
            defaultFollowUpMessage: props.defaultFollowUpMessage,
            emailSignature: props.emailSignature,
            profileImage: props.profileImage || "",
        };
    },

    validate: async values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Your name is required.'
        }

        if (!values.defaultWelcomeMessage) {
            errors.defaultWelcomeMessage = 'A default Welcome Message is required.';
        }

        if (!values.defaultFollowUpMessage) {
            errors.defaultFollowUpMessage = 'A default Follow Up Message is required.';
        }

        return errors;
    },

    handleSubmit: (values, { props, resetForm }) => {
        props.updateProfile({
            name: values.name,
            defaultWelcomeMessage: values.defaultWelcomeMessage,
            defaultFollowUpMessage: values.defaultFollowUpMessage,
            emailSignature: values.emailSignature,
            profileImage: values.profileImage,
        });
        resetForm();
    },

    enableReinitialize: true,
    displayName: 'ProfileSettingsForm',
})(ProfileSettings);

ProfileSettingsForm.propTypes = {
    updateProfile: PropTypes.func.isRequired,
    defaultWelcomeMessage: PropTypes.string.isRequired,
    defaultFollowUpMessage: PropTypes.string.isRequired,
};

export default ProfileSettingsForm;
