import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button/Button.jsx';
import Spinner from 'components/common/Spinner/Spinner.jsx';
import "components/common/Dialog/Dialog.scss";

const Dialog = ({
    title,
    subtitle,
    buttonTitle,
    showButton,
    showSpinner,
    onClick,
}) => (
    <div className="DialogContainer">
        <div className="Dialog">
            <div className="DialogTitle">{title}</div>
            <div className="DialogSubtitle">{subtitle}</div>
            { showButton && <button className="DialogButton" onClick={onClick}><Button title={buttonTitle} /></button> }
            { showSpinner && <Spinner /> }
        </div>
    </div>
);

Dialog.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonTitle: PropTypes.string,
    showButton: PropTypes.bool,
    showSpinner: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

Dialog.defaultProps = {
    title: "",
    subtitle: "",
    buttonTitle: "",
    showButton: true,
    showSpinner: false,
    onClick: () => {},
};

export default Dialog;
