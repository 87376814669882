import React, { useState } from 'react';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaList, FaUserCircle, FaCog, FaBars, FaTimes, FaPlus } from 'react-icons/fa';
import Button from 'components/common/Button/Button.jsx';
import HR from 'components/common/HR/HR.jsx';
import "components/Sidebar/Sidebar.scss";
import OpenHomely_Logo from 'assets/OpenHomely_Logo.svg';

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const closeSidebar = () => setIsOpen(false);
    const location = useLocation();
    return (
        <div className={
            cx(
                "SidebarContainer",
                {
                    "SideBarContainer--open": isOpen
                }

            )
        }>
            <div className="SidebarTop">
                <div className={
                    cx(
                        'SidebarHead',
                        {
                            "SidebarHead--open": isOpen
                        }
                    )
                }>
                    <div>
                        <Link to="/app/dashboard"><img className="SidebarLogoImg" onClick={closeSidebar} src={OpenHomely_Logo} title="OpenHomelyLogo" alt="OpenHomely" /></Link>
                        <HR className="SidebarLogoHr"/>
                    </div>
                    {isOpen ? <FaTimes className="SidebarHeadButton" onClick={() => setIsOpen(!isOpen)} /> : <FaBars className="SidebarHeadButton" onClick={() => setIsOpen(!isOpen)} /> }
                </div>
                <div className="SidebarCTA">
                    <Link to="/app/homes/add" onClick={closeSidebar}><Button title="Add Home" className="SidebarCTAButton" ButtonIcon={FaPlus} /></Link>
                </div>
                <div className="SidebarLinks">
                    <div className={cx("SidebarLink", {"SidebarLink--active": location.pathname === "/app/dashboard"})} onClick={closeSidebar}>
                        <FaHome /><Link to="/app/dashboard"><span>Dashboard</span></Link>
                    </div>
                    <div className={cx("SidebarLink", {"SidebarLink--active": location.pathname === "/app/homes"})} onClick={closeSidebar}>
                        <FaList /><Link to="/app/homes"><span>Homes</span></Link>
                    </div>
                    <div className={cx("SidebarLink", {"SidebarLink--active": location.pathname === "/app/leads"})} onClick={closeSidebar}>
                        <FaUserCircle /><Link to="/app/leads"><span>Leads</span></Link>
                    </div>
                    <div className={cx("SidebarLink", {"SidebarLink--active": location.pathname === "/app/settings"})} onClick={closeSidebar}>
                        <FaCog /><Link to="/app/settings"><span>Settings</span></Link>
                    </div>
                </div>
            </div>
            <div className="SidebarBottom">
                <div className="SidebarContact">
                    Need Support? <a href="mailto:support@openhomely.com">support@openhomely.com</a>
                </div>
                <div className="SidebarMadeWithMsg">
                    Made for you with ♥️ in beautiful Vancouver BC, Canada
                </div>
            </div>
        </div>
    )
};

Sidebar.propTypes = {

};

export default Sidebar;
